import React from "react";
import {
  Form,
  FormGroup,
  Input,
  Spinner,
  Row,
} from 'reactstrap';
import Cookies from 'js-cookie';
import Button from '../../components/CustomButton/CustomButton';
import Notification from '../../components/Notification/Notification';
import { GoogleLogin } from 'react-google-login';
import NovoUsuarioForm from '../Clientes/NovoClienteForm';
import CpfCnpj from '../../components/CpfCnpj/CpfCnpjComponent';

import Api from '../../config/Api';

import './style.css';
import './style.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('TOKEN'),
      username: '',
      usuarioId: '',
      password: '',
      showPass: false,
      loading: false,
      needClientRegister: false,
      cliente: {},
      documento: '',
      useClientExistingData: false,
    }
    this.api = new Api(this.state.token);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  submitLogin = async (event) => {
    event && event.preventDefault();
    try {
      this.setState({
        loading: true,
      });
      const { data } = await this.api.login(this.state.username, this.state.password);
      if (data.token) {
        this.clearOldCookies();
        Cookies.set('TOKEN', data.token);
        Cookies.set('USUARIO', data.nomeCompleto);
        Cookies.set('USERNAME', data.id);
        this.props.history.push({
          pathname: '/home',
          state: { routes: data.routes },
        });
      } else if (data.code) {
        this.abrirNotificacaoErro('Usuário ou senha inválido.')
      } else {
        this.abrirNotificacaoErro('Usuário não cadastrado.')
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.abrirNotificacaoErro('Erro de conexão.');
    }
  }

  setClientExistingFlag = () => {
    this.setState((prevState) => ({
      useClientExistingData: !prevState.useClientExistingData,
    }));
  }

  setClientExistingData = (clientData) => {
    this.setState({
      cliente: clientData,
      showClientForm: true,
    });
  }

  setLoginForm = () => {
    return this.setState({
      needClientRegister: false,
    });
  }

  getUserByDocument = () => {

  }

  clearOldCookies = () => {
    Cookies.remove('USUARIO');
  }

  handleShowPassword = () => {
    this.setState((prevState) => ({
      showPass: !prevState.showPass,
    }));
  }

  pushToHome = (data) => {
    return this.props.history.push({
      pathname: '/home',
      state: { routes: data.routes },
    });
  }

  responseGoogle = async (response) => {
    try {
      const { accessToken, profileObj } = response;
      const { data } = await this.api.googleLogin(accessToken, profileObj);

      if (data.token) {
        this.clearOldCookies();
        Cookies.set('TOKEN', data.token);
        Cookies.set('USUARIO', data.nomeCompleto);
        Cookies.set('USERNAME', data.id);
        this.props.history.push({
          pathname: '/home',
          state: { routes: data.routes },
        });
      }

      this.setState({
        needClientRegister: data.needClientCreation,
        usuarioId: data.id,
        email: profileObj.email,
        showClientForm: false,
      });
    } catch (error) {
      this.abrirNotificacaoErro('Erro de conexão.');
    }
  }

  abrirNotificacaoErro = (message) => Notification
    .createNotification('error', message)

  abrirNotificacaoInfo = (message) => Notification.createNotification('info', message);

  abrirNotificacaoSucesso = (message) => Notification
    .createNotification('success', message ? message : 'Operação realizada com sucesso!');

    handleCustomWrapperClassName = () => {
      if (this.state.needClientRegister && !this.state.showClientForm) {
        return 'custom-wrapper-1';
      }
      if (this.state.needClientRegister && this.state.showClientForm) {
        return 'custom-wrapper-2';
      }
      return 'wrapper';
    }



  render() {
    const inStyle = { background: "black", outline: 'none' };
    return (
      <>
        <div
          // className="wrapper background-login login-container"
          className={`${this.handleCustomWrapperClassName()} background-login login-container`}
        >
          <div
            // className="center-panel background-central-panel"
            className={`${this.state.needClientRegister ? 'custom-center-panel' : 'center-panel'} background-central-panel`}
          >
            {this.state.needClientRegister ? (
              <>
                <p className="register-text font-color">Cadastre-se</p>
                <div className="custom-expense-modal">
                  <Row>
                    <CpfCnpj
                      api={this.api}
                      abrirNotificacaoErro={this.abrirNotificacaoErro}
                      usuarioId={this.state.usuarioId}
                      documento={this.state.documento}
                      setClientExistingFlag={this.setClientExistingFlag}
                      setClientExistingData={this.setClientExistingData}
                      handleInputChange={this.handleInputChange}
                      loginView
                    />
                  </Row>
                  {this.state.showClientForm && (
                    <NovoUsuarioForm
                      edit={this.state.useClientExistingData}
                      api={this.api}
                      cliente={this.state.cliente}
                      email={this.state.email}
                      documento={this.state.documento}
                      modal={this.handleClientModal}
                      abrirNotificacaoSucesso={this.abrirNotificacaoSucesso}
                      abrirNotificacaoErro={this.abrirNotificacaoErro}
                      abrirNotificacaoInfo={this.abrirNotificacaoInfo}
                      plataformaUsuarioId={this.state.usuarioId}
                      createdByLogin
                      setLoginForm={this.setLoginForm}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="login-logo">
                  <div className="app-login">
                  </div>
                </div>
                <p className="login-text font-color">Login</p>
                <Form onSubmit={this.submitLogin} >
                  <div className="your-custom-class">
                    <GoogleLogin
                      // className="your-custom-class" 
                      buttonText="Entrar com Google"
                      clientId="756758406957-1bstcvo03he74vpuum1974bsp2fu9ff7.apps.googleusercontent.com"
                      style={inStyle}
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                    />
                  </div>
                  <div className="content-google-auth">
                    <p className="or">ou</p>
                  </div>
                  <FormGroup>
                    <Input
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Digite seu nome de usuário"
                      onChange={this.handleInputChange}
                      required={true}
                      minLength="3" />
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Senha"
                      onChange={this.handleInputChange}
                      required={true}
                      minLength="6" />
                    <Button color="success" type="submit">
                      {!this.state.loading ? (
                        'Acessar'
                      ) : (
                        <div className="load-spinner-center-table-pagined custom-spinner-login">
                          <Spinner type="grow" size="sm" color="white" />
                          {' '}
                          <Spinner type="grow" size="sm" color="white" />
                          {' '}
                          <Spinner type="grow" size="sm" color="white" />
                        </div>
                      )}
                    </Button>
                  </FormGroup>
                </Form>
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default Login;
