import React from "react";
import Cookies from 'js-cookie';
import Moment from 'moment';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Badge,
} from "reactstrap";
import NovoItemForm from './NovoItemForm';
import ReciboItem from './ReciboItem';
import Money from '../../utils/Money';
import Filtros from './Filtros';
import Notification from '../../components/Notification/Notification';
import confirm from 'reactstrap-confirm';
import Api from '../../config/Api';

export default class Produto extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('TOKEN'),
      usuarioId: Cookies.get('USERNAME'),
      itens: [],
      item: {},
      edit: false,
      itemModal: false,
      filtered: false,
      filterModal: false,
      dataLoaded: false,
      offset: 0,
      count: 0,
      loading: false,
      filters: {},
    };

    this.api = new Api(this.state.token, this.state.usuarioId, 'fila-de-produtos', (err) => {
      if (err.response.status === 401)
        this.props.history.push({
          pathname: '/login',
          state: { previousURL: this.props.location.pathname }
        });
        Cookies.remove('TOKEN');
        Cookies.remove('USUARIO');
        Cookies.remove('USERNAME');
        localStorage.clear();
      return Promise.reject(err);
    });
  }

  async componentDidMount() {
    const payload = {
      situacao: 'EM_ABERTO',
    };
    await this.getItens(0, payload, {}, true);
  }

  getItens = async (offsetParams, payload, filtersOptionsParam, dataLoaded) => {
    try {
      if (!dataLoaded) {
        this.setState({
          loadingMore: true,
        });
      }
      const newPayload = { ...payload };
      newPayload.userId = this.state.usuarioId;
      const offsetToUse = offsetParams !== undefined ? offsetParams : this.state.offset;

      const { data } = await this.api.getItens(10, offsetToUse, newPayload);

      let itensConcat = [];
      if (offsetToUse !== 0) {
        itensConcat = itensConcat.concat(this.state.itens);
      }
      itensConcat = itensConcat.concat(data.rows);

      const newOffset = offsetToUse + data.rows.length;
      this.setState({
        itens: itensConcat,
        offset: newOffset,
        count: data.count,
        loadingMore: false,
        dataLoaded: true,
        filtersOptions: filtersOptionsParam || {},
        filters: payload,
        totalItens: itensConcat.reduce((acc, cv) => acc + Number(cv.valorTotalItem), 0).toFixed(2),
        situacao: newPayload.situacao,
        currentUserAdmin: data.admin,
      });
    } catch (err) {
      console.log(err);
    }
  }

  openEditModal = async (productId) => {
    try {
      const { data } = await this.api.getSingleItem(productId);
      this.setState({
        itemModal: !this.state.itemModal,
        item: data,
        edit: true
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleItemModal = (evt) => {
    this.setState({
      itemModal: !this.state.itemModal,
      item: {},
      edit: false,
    })
  }

  handleCancelItem = async (itemId) => {
    const confirmation = await confirm({
      title: 'Atenção',
      message: `O Item será excluído. Tem certeza que deseja continuar?`,
      confirmText: 'Sim',
      cancelText: 'Não',
      confirmColor: "success",
    });
    if (confirmation) {
      const { data } = await this.api.deleteItem(itemId);
      if (data.status) {
        this.getItens(0, { situacao: this.state.situacao }, {}, true);
        this.abrirNotificacaoSucesso(data.message)
      } else {
        this.abrirNotificacaoErro('Erro ao excluir item.');
      }
    }
  }

  handleLiberarItem = async (itemId) => {
    try {
      const confirmation = await confirm({
        title: 'Atenção',
        message: `O produto será liberado. Tem certeza que deseja continuar?`,
        confirmText: 'Sim',
        cancelText: 'Não',
        confirmColor: "success",
      });
      if (confirmation) {
        const situacao = this.state.situacao;
        const { data } = await this.api.liberarItem(itemId, { situacao });
        await this.getItens(0, { situacao }, {}, true);
        if (data && data.status) {
          this.abrirNotificacaoSucesso('Item liberado com sucesso!');
        }
      }
    } catch (err) {
      this.abrirNotificacaoErro('Erro ao liberar item.');
    }
  }

  gerarRecibo = async (itemId) => {
    const itemFind = this.state.itens.find((item) => item.id === itemId);
    this.setState((prevState) => ({
      printReportModal: !prevState.printReportModal,
      item: itemFind,
    }));
  }

  abrirNotificacaoSucesso = (message) => Notification
    .createNotification('success', message ? message : 'Operação realizada com sucesso!')

  abrirNotificacaoErro = (message) => Notification
    .createNotification('error', message)

  toggleFilterModal = () => {
    this.setState({
      filterModal: !this.state.filterModal,
    });
  }

  toogleReport = () => {
    this.setState((prevState) => ({
      printReportModal: !prevState.printReportModal,
    }));
  }

  removeFilter = () => {
    const payload = {
      situacao: this.state.situacao,
    };
    this.setState({
      filtered: false,
      dataLoaded: false,
    }, () => this.getItens(0, payload));
  }

  loadData = (offset, payload, filtersOptions) => {
    const newPayload = payload || this.state.filters;
    newPayload.situacao = this.state.situacao;
    if (payload && payload.dataFim) {
      newPayload.dataFim = `${payload.dataFim} 23:59`
    }
    const newFiltersOptions = filtersOptions || this.state.filtersOptions
    this.setState({
      filtered: payload || Object.entries(this.state.filters).length > 2
        ? true : false,
      dataLoaded: !payload ? true : false,
    }, () => this.getItens(offset, newPayload, newFiltersOptions));
  }

  handleStatusChange = (status) => {
    const payload = {
      situacao: status,
    };
    return this.setState({
      dataLoaded: false,
    }, () => this.getItens(0, payload));
  }

  handleDecimalValue = (value) => {
    return value && Number(value).toFixed(2);
  }

  handleReloadGrid = () => {
    const payload = {
      situacao: this.state.situacao,
    };
    this.setState({
      dataLoaded: false,
    }, () => this.getItens(0, payload));
  }

  checkItensAmount = (item) => {
    if (item.itemProduto && item.itemTela) {
      return item.itemProduto.length + item.itemTela.length;
    }
    return item.itemProduto ? item.itemProduto.length : item.itemTela.length;
  }

  render() {
    return (
      <>
        <div className="content">
          {this.state.dataLoaded ? (
            <>
              <Row className="row-Stats">
                <Col xs={12} sm={6} md={6} lg={3} className="col-Stats">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col xs={5} md={4}>
                          <div className="icon-big text-center text-success">
                            <i className="fas fa-money-check-alt" />
                          </div>
                        </Col>
                        <Col xs={7} md={8}>
                          <div className="numbers">
                            <p className="card-category">Total</p>
                            <CardTitle tag="p">
                              {this.state.totalItens ? Money(this.state.totalItens) : 0}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <button
                className="btn btn-primary novo-cadastro-button-margin"
                onClick={() => this.handleItemModal()}
              >
                <i className="fas fa-folder-plus"></i> Nova Venda
              </button>
              <Row>
                <Col className="ml-auto mr-auto" md="11">
                  <Card className="card-upgrade">
                    <CardHeader className="text-center">
                      <Row>
                        <Col md="10">
                          <CardTitle tag="h4">
                            Fila Produtos
                            {' '}
                            <UncontrolledDropdown group size="sm">
                              <DropdownToggle caret color="primary">
                                {this.state.situacao === 'EM_ABERTO' ? 'Em aberto' : 'Atendidos'}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  title="Em aberto"
                                  onClick={() => this.handleStatusChange('EM_ABERTO')}
                                >
                                  <i className="fas fa-file-invoice-dollar"></i> Venda em aberto
                                </DropdownItem>
                                <DropdownItem
                                  title="Atendido"
                                  onClick={() => this.handleStatusChange('ATENDIDO')}
                                >
                                  <i className="fas fa-check"></i> Venda atendida
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </CardTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="flex">
                          <Button className="filter-margin" color="primary" outline onClick={this.toggleFilterModal} size="sm">
                            <i className="fas fa-filter"></i> Filtros
                          </Button>
                          {this.state.filtered && (
                            <Button color="primary" outline onClick={this.removeFilter} size="sm">
                              <i className="fas fa-trash"></i> Limpar
                            </Button>
                          )}
                        </Col>
                        {!this.state.filtered && (
                          <Col md="1" className="reload-btn-margin">
                            <Button
                              color="primary"
                              outline
                              onClick={() => this.handleReloadGrid()}
                              size="sm"
                            >
                              <i className="fas fa-redo"></i>
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Table responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Ações</th>
                            <th className="text-center">Cliente</th>
                            <th className="text-center">Quantidade Itens</th>
                            <th className="text-center">Vlr Total</th>
                            <th className="text-center">Data Venda</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.itens.map((item) => {
                            return (
                              <tr
                                key={item.id}
                              >
                                <td>
                                  <UncontrolledDropdown group>
                                    <DropdownToggle caret color="primary">
                                      <i className="fas fa-cog"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        title="Editar"
                                        onClick={() => this.openEditModal(item.id)}
                                      >
                                        <i className="fas fa-edit"></i> Editar
                                      </DropdownItem>
                                      {item.situacao !== 'ATENDIDO' && this.state.currentUserAdmin
                                        && Number(item.valorTotalItem) !== 0 && (
                                          <DropdownItem
                                            title="Liberar"
                                            onClick={() => this.handleLiberarItem(item.id)}
                                          >
                                            <i className="fas fa-check"></i> Liberar
                                          </DropdownItem>
                                        )}
                                      {this.state.currentUserAdmin && (
                                        <DropdownItem
                                          title="Recibo"
                                          onClick={() => this.gerarRecibo(item.id)}
                                        >
                                          <i className="fas fa-receipt"></i> Recibo
                                        </DropdownItem>
                                      )}
                                      <DropdownItem
                                        title="Excluir"
                                        onClick={() => this.handleCancelItem(item.id)}
                                      >
                                        <i className="fas fa-trash"></i> Excluir
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                                <td className="text-center">
                                  {item.clienteNome}
                                </td>
                                <td className="text-center">
                                  {this.checkItensAmount(item)}
                                </td>
                                <td className="text-center">
                                  {Money(item.valorTotalItem)}
                                </td>
                                <td className="text-center">
                                  {item.dataCriacao && Moment(item.dataCriacao).format('DD-MM-YYYY HH:mm')}
                                </td>
                                {Number(item.valorTotalItem) === 0 && this.state.currentUserAdmin && (
                                  <td>
                                    <Badge pill variant="success">Novo</Badge>
                                  </td>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="show-more-center-table-pagined">
                      {this.state.loadingMore ? (
                        <div className="load-spinner-center-table-pagined">
                          <Spinner size="sm" color="primary" />
                          {' '}
                        </div>
                      ) : (
                        <Button color="primary"
                          id="mostrarMaisBtn"
                          outline
                          onClick={() => this.loadData()}
                          disabled={this.state.itens.length === parseInt(this.state.count)}
                          size="sm">
                          Mostrar Mais
                        </Button>
                      )}
                    </div>
                    <div className="info-pagination-pagination">
                      <h3>
                        Mostrando
                        {' '}
                        {this.state.itens && this.state.itens.length}
                        {' '}
                        de
                        {' '}
                        {this.state.count}
                        {' '}
                        {'Itens'}
                      </h3>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <div className="fila-impressao-spinner">
              <Spinner style={{
                width: '7rem',
                height: '7rem',
              }} color="primary" />
            </div>
          )
          }
        </div>
        <Modal className="new-expense-modal" isOpen={this.state.itemModal} toggle={this.handleItemModal}>
          <ModalHeader toggle={this.handleItemModal}>
            {this.state.edit ? 'Venda' : 'Nova Venda'}
          </ModalHeader>
          <ModalBody>
            <NovoItemForm
              item={this.state.item}
              edit={this.state.edit}
              api={this.api}
              loadData={this.getItens}
              modal={this.handleItemModal}
              abrirNotificacaoSucesso={this.abrirNotificacaoSucesso}
              abrirNotificacaoErro={this.abrirNotificacaoErro}
              usuarioId={this.state.usuarioId}
              situacao={this.state.situacao}
              currentUserAdmin={this.state.currentUserAdmin}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleItemModal}>Fechar</Button>
          </ModalFooter>
        </Modal>
        <Modal className="new-expense-modal" isOpen={this.state.filterModal} toggle={this.toggleFilterModal}>
          <ModalHeader toggle={this.toggleFilterModal}>Filtros</ModalHeader>
          <ModalBody>
            <Filtros
              api={this.api}
              modal={this.toggleFilterModal}
              loadData={this.loadData}
              abriNotificacaoSucesso={this.abriNotificacaoSucesso}
              usuarioId={this.state.usuarioId}
              filters={this.state.filters}
              filtersOptions={this.state.filtersOptions}
              situacao={this.state.situacao}
              currentUserAdmin={this.state.currentUserAdmin}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleFilterModal}>Fechar</Button>
          </ModalFooter>
        </Modal>
        <Modal className="new-expense-modal" isOpen={this.state.printReportModal} toggle={this.toogleReport}>
          <ModalHeader toggle={this.toogleReport}>Imprimir relatorio</ModalHeader>
          <ModalBody>
            <ReciboItem
              venda={this.state.item}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toogleReport}>Fechar</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
