/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'reactstrap';

const Dropzone = (props) => {
  const [acceptedFiles, setFiles] = useState([]);
  const submit = (files) => {
    props.onSubmit(files);
  };
  const { rejectedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: props.maxSize,
    accept: props.acceptedFormats,
    onDrop: (selectedFiles) => {
      const files = selectedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }));
      submit(files);
    },
  });

  const verifyInvalidFormats = (rejectds, validFormats) => {
    const isInvalid = rejectds?.some((rejected) => {
      const type = rejected.type.match(/\d*\/(\w*)/);

      if (!type || type.length !== 2) {
        return true;
      }

      if (type && type[1] && validFormats.indexOf(type[1]) === -1) {
        return true;
      }

      return false;
    });

    return isInvalid;
  };

  const files = acceptedFiles.map((file) => (
    <div key={file.path}>
      { props.logo && (
        <img className="logo-upload" src={file.preview} alt="company logo" />
      )}
      <p>{file.path}</p>
    </div>
  ));

  const clearDropzone = () => {
    setFiles([]);
  };

  const isFileInvalidFormat = verifyInvalidFormats(rejectedFiles, props.validExtensions);
  const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0]?.size > props.maxSize;
  return (
    <section className="container">
      {acceptedFiles.length < props.maxFiles && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div>
            <p>{props.uploadMessage}</p>
            <em>{props.uploadSubMessage}</em>
            <p className="dropzone-error text-center">{isFileTooLarge ? 'Este arquivo é muito grande.' : ''}</p>
            <p className="dropzone-error text-center">
              {isFileInvalidFormat && (
                `Este arquivo não é um dos formatos aceitos, tente um desses:
                ${props.validExtensions.join(', ')}.`
              )}
            </p>
          </div>
          {props.children}
        </div>
      )}
      {acceptedFiles.length > 0 && ( // disabled for now
        <aside>
          <h4>Arquivo</h4>
          {files}
          <div>
            <Button color="success" type="button" onClick={submit}>
              Salvar
              {' '}
              <i className="fas fa-save" />
            </Button>
            <Button color="info" type="button" onClick={clearDropzone}>
              Limpar arquivo(s)
            </Button>
          </div>

        </aside>
      )}
    </section>
  );
};

export default Dropzone;
