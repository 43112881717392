import React from "react";
import {
  FormGroup,
  Label,
  Input,
  Form,
  Col,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  Spinner,
} from "reactstrap";
import MaskedInput from 'react-text-mask';
import CpfCnpj from '../../components/CpfCnpj/CpfCnpjComponent';

class NovoClienteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cliente: props.cliente,
      disableSubmitButton: false,
      nome: props.cliente?.nome,
      telefone1: props.cliente?.telefone1,
      telefone2: props.cliente?.telefone2,
      documento: props.cliente?.documento,
      contato: props.cliente?.contato,
      bairro: props.cliente?.bairro,
      cep: props.cliente?.cep,
      logradouro: props.cliente?.logradouro,
      numero: props.cliente?.numero,
      cidade: props.cliente?.cidade,
      estado: props.cliente?.estado,
      email: props.cliente?.email,
      cnpjAlreadyInUse: false,
      cpfAlreadyInUse: false,
      loadingCEP: false,
      collapseNewUser: false,
      mostrarSenha: false,
      createClient: props.plataforma_usuario_id,
      username: props.cliente?.Usuario?.username,
      userCreated: props.cliente?.Usuario,
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleCepChange = async (evt) => {
    const cep = evt && evt.target.value;
    this.setState({
      cep,
    });
    if (cep.length === 8) {
      this.setState({
        loadingCEP: true,
      });
      const { data } = (await this.props.api.getAddressByCep(cep));
      if (data.erro) {
        this.props.abrirNotificacaoInfo('CEP não encontrado.');
      } else {
        this.fillAdress(data);
      }
      this.setState({
        loadingCEP: false,
      });
    }
  }

  fillAdress = (adress) => {
    return this.setState({
      bairro: adress.bairro,
      cidade: adress.localidade,
      logradouro: adress.logradouro,
      estado: adress.uf,
    });
  }

  handleSelectChange = param => evt => {
    this.setState({
      [param]: evt.value,
    });
  }

  handleMoneyInputChange = (event) => {
    const name = event.target.name;

    if (event.money) {
      this.setState({ [name]: event.money.actualNumber });
    }
  }

  toggleCollapse = (evt) => {
    this.setState({
      collapseNewUser: !this.state.collapseNewUser,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.disableSubmitButton();


    try {
      const sendObject = {
        nome: this.state.nome,
        telefone1: this.state.telefone1 && this.clearString(this.state.telefone1, true),
        telefone2: this.state.telefone2 && this.clearString(this.state.telefone2, true),
        documento: this.state.documento || this.props.documento,
        contato: this.state.contato,
        email: this.state.email || this.props.email,
        usuarioId: !this.props.createdByLogin ? this.props.usuarioId : null,
        plataformaUsuarioId: this.props.plataformaUsuarioId,
        clienteEndereco: {
          bairro: this.state.bairro,
          cep: this.state.cep,
          logradouro: this.state.logradouro,
          cidade: this.state.cidade,
          estado: this.state.estado,
          numero: this.state.numero,
        }
      }
      if (!this.props.edit) {
        await this.props.api.createClient(sendObject, true);
      } else {
        await this.props.api.updateClient(this.props.cliente.id || this.state.cliente.id, sendObject, true);
      }

      if (this.props.createdByLogin) {
        this.props.setLoginForm();
      } else {
        this.props.modal();
        this.props.loadData(0);
      }
      this.props.abrirNotificacaoSucesso();
    } catch (err) {
      this.disableSubmitButton();
      console.log(err);
    }
  }

  disableSubmitButton = () => {
    this.setState((prevState) => ({ disableSubmitButton: !prevState.disableSubmitButton }));
  }

  enableSubmitButton = () => {
    this.setState((prevState) => ({ disableSubmitButton: false }));
  }

  getTelefoneMask = (rawValue) => {
    const unmakedValue = this.clearString(rawValue);
    if (unmakedValue.length > 12) {
      return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  clearString = (string, sendObject) => {
    let clearedString = string.replace(/[_|/|.|-]/g, '');
    if (sendObject) {
      // remove the parentheses
      clearedString = clearedString.replace(/[()|.| ]/g, '');
    }
    return clearedString;
  }

  handleMostrarSenhaCheckBox = (evt) => {
    const target = evt.target
    this.setState({
      mostrarSenha: target.checked,
    })
  }

  render() {
    const { cliente, logradouro, cidade, estado, bairro, cep } = this.state;
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <h5 className="separator-form">Dados Gerais</h5>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="nome">Nome Completo</Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  onChange={this.handleInputChange}
                  defaultValue={cliente?.nome}
                  required={true}
                  placeholder="Digite aqui"
                  autoComplete="new-text"
                  disabled={this.props.detail}
                  className="upper-case-input"
                />
              </FormGroup>
            </Col>
            {!this.props.createdByLogin && (
              <Col md="6">
                <CpfCnpj
                  api={this.props.api}
                  abrirNotificacaoErro={this.props.abrirNotificacaoErro}
                  usuarioId={this.props.usuarioId}
                  documento={this.state.documento}
                  handleInputChange={this.handleInputChange}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="telefone1">Telefone 1</Label>
                <MaskedInput
                  mask={this.getTelefoneMask}
                  className="form-control upper-case-input"
                  guide
                  required
                  name="telefone1"
                  id="telefone1"
                  defaultValue={cliente?.telefone1}
                  placeholder="Digite aqui"
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="telefone2">Telefone 2</Label>
                <MaskedInput
                  mask={this.getTelefoneMask}
                  className="form-control upper-case-input"
                  guide
                  name="telefone2"
                  id="telefone2"
                  defaultValue={cliente?.telefone2}
                  placeholder="Digite aqui"
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="contato">Contato</Label>
                <Input
                  type="text"
                  name="contato"
                  id="contato"
                  onChange={this.handleInputChange}
                  defaultValue={cliente?.contato}
                  required={true}
                  placeholder="Digite aqui"
                  disabled={this.props.detail}
                  className="upper-case-input"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <FormGroup>
                <Label for="email">E-mail</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  onChange={this.handleInputChange}
                  defaultValue={cliente?.email || this.props.email}
                  placeholder="Digite aqui"
                  disabled={this.props.detail}
                  className="upper-case-input"
                />
              </FormGroup>
            </Col>
          </Row>
          {/* {this.state.loadingNewUser ? (
            <>
              <hr />
              <div style={{ textAlign: 'center' }}>
                <Spinner style={{
                  width: '7rem',
                  height: '7rem',
                }} color="primary" />
              </div>
            </>
          ) : !this.props.createdByLogin && (
            <>
              <hr />
              <h5>Dados Usuário
                {!this.state.collapseNewUser && (
                  <Button color="primary" fab="true" round="true" icon
                    onClick={this.toggleCollapse}
                    className="btn-round btn-icon round-btn-add">
                    <i className="fa fa-plus"></i>
                  </Button>
                )}
                {this.state.collapseNewUser && (
                  <Button color="primary" fab round icon
                    onClick={this.toggleCollapse}
                    className="btn-round btn-icon round-btn-add">
                    <i className="fa fa-minus"></i>
                  </Button>
                )}
              </h5>
              <Collapse isOpen={this.state.collapseNewUser}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="username">Usuário</Label>
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Usuário"
                        onChange={this.handleInputChange}
                        disabled={this.state.userCreated}
                        defaultValue={this.state.username}
                        // required={true}
                        minLength="3" />
                    </FormGroup>
                  </Col>
                  {!this.state.userCreated && (
                    <Col>
                      <FormGroup>
                        <Label for="password">Senha</Label>
                        <Input
                          type={this.state.mostrarSenha ? 'text' : 'password'}
                          name="password"
                          id="password"
                          placeholder="Senha"
                          onChange={this.handleInputChange}
                          // required={true}
                          disabled={this.state.userCreated}
                          defaultValue={this.state.password}
                          autoComplete="new-password"
                          minLength="6" />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="googleDrivePath">Link Google Drive</Label>
                      <Input
                        type="text"
                        name="googleDrivePath"
                        id="googleDrivePath"
                        placeholder="Digite aqui"
                        onChange={this.handleInputChange}
                        // disabled={this.state.userCreated}
                        defaultValue={this.state.googleDrivePath}
                        // required={true}
                        minLength="3" />
                    </FormGroup>
                  </Col>
                </Row>
                {!this.state.userCreated && (
                  <Row>
                    <Col>
                      <Button
                        className="btn btn-primary"
                        round="true"
                        onClick={this.handleNewUserSubmit}
                        disabled={this.state.userCreated}
                      >
                        Salvar  <i className="fas fa-save"></i>
                      </Button>
                    </Col>
                    <Col>
                      <Col>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox"
                              name="mostrarsenha"
                              onChange={this.handleMostrarSenhaCheckBox}
                            />{' '}
                            Mostrar senha
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Col>
                  </Row>
                )}
              </Collapse>
            </>
          )} */}
          <hr />
          <h5 className="separator-form">Endereço</h5>
          <Row>
            <Col md="6">
              <Label for="cep">CEP</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend" className="custom-input-group-pretend">
                  {!this.state.loadingCEP ? (
                    ''
                  ) : (
                    <div
                      className="group-input-custom-content"
                    >
                      <Spinner size="sm" color="primary" />
                    </div>
                  )}
                </InputGroupAddon>
                <Input
                  className="custom-password-input-group upper-case-input"
                  type="cep"
                  name="cep"
                  id="cep"
                  required
                  placeholder="Digite aqui"
                  defaultValue={cliente?.clienteEndereco ?
                    cliente.clienteEndereco.cep : cep}
                  onChange={this.handleCepChange}
                  disabled={this.props.detail}
                  maxLength={8}
                />
              </InputGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="logradouro">Logradouro</Label>
                <Input
                  type="logradouro"
                  name="logradouro"
                  id="logradouro"
                  required
                  placeholder="Digite aqui"
                  defaultValue={cliente?.clienteEndereco
                    ? cliente.clienteEndereco.logradouro : logradouro}
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                  className="upper-case-input"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="bairro">Bairro</Label>
                <Input
                  type="text"
                  name="bairro"
                  id="bairro"
                  required
                  placeholder="Digite aqui"
                  defaultValue={cliente?.clienteEndereco
                    ? cliente.clienteEndereco?.bairro : bairro}
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                  className="upper-case-input"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="numero">Número</Label>
                <Input
                  type="numero"
                  name="numero"
                  id="numero"
                  required
                  placeholder="Digite aqui"
                  className="upper-case-input"
                  defaultValue={cliente?.clienteEndereco?.numero}
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="cidade">Cidade</Label>
                <Input
                  type="text"
                  name="cidade"
                  id="cidade"
                  required
                  placeholder="Digite aqui"
                  defaultValue={cliente?.clienteEndereco
                    ? cliente.clienteEndereco?.cidade : cidade}
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                  className="upper-case-input"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="estado">Estado</Label>
                <Input
                  type="estado"
                  name="estado"
                  id="estado"
                  required
                  placeholder="Digite aqui"
                  defaultValue={cliente?.clienteEndereco
                    ? cliente.clienteEndereco?.estado : estado}
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                  className="upper-case-input"
                />
              </FormGroup>
            </Col>
          </Row>
          {
            !this.props.detail && (
              <Button
                className="btn btn-primary"
                round="true"
                type="submit"
                disabled={this.state.disableSubmitButton}
              >
                Salvar  <i className="fas fa-save"></i>
              </Button>
            )
          }
        </Form >
      </>
    );
  }
}

export default NovoClienteForm;