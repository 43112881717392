import React, { Component } from 'react';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import { Spinner } from 'reactstrap';
import money from '../../../utils/Money';
import Roboto from '../../../assets/fonts/Roboto-Bold.ttf';
import Moment from 'moment';
import DateHelper from '../../../utils/Date';

const styles = StyleSheet.create({
  title: {
    marginLeft: 32,
    marginBottom: 5,
    fontSize: 17,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  totalTitle: {
    marginLeft: 5,
    marginBottom: 5,
    fontSize: 17,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  subitle: {
    marginLeft: 10,
    fontSize: 13,
    marginBottom: 10,
  },
  subitleFilter: {
    marginLeft: 32,
    fontSize: 13,
    marginBottom: 10,
  },
  body: {
    flexGrow: 1,
    margin: 10,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  table: {
    display: "table",
    width: "auto",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    marginTop: 2,
    marginBottom: 4,
    paddingBottom: 4,
    paddingTop: 2,
  },
  style1: {
    backgroundColor: '#EFEFEF'
  },
  tableHeader: {
    color: '#6d6c6c',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    borderStyle: "solid",
    borderBottom: 1,
    textAlign: 'left',
  },
  tableCol: {
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'left'
  },
  tableCell: {
    margin: "auto",
    fontSize: 12,
    height: 'auto',
    width: '100%'
  },
  xlCol: {
    width: "100px",
  },
  mdCol: {
    width: "70px",
  },
  smCol: {
    width: "50px",
  },
  totalsBody: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: 30,
    marginRight: 60,
  },
  alignLeft: {
    textAlign: 'left'
  },
  alignCenter: {
    textAlign: 'center'
  }
});

Font.register({ family: 'Roboto', src: Roboto });

class ImprimirItensPDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itensData: this.props.data,
      dataLoaded: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        dataLoaded: true,
      });
    }, 400)
  }

  handlePeriod = () => {
    const dataFim = this.props.dataFim ? this.props.dataFim
      : DateHelper.currentMonth().endOfMonth;
    return ` Produtos de ${Moment(this.props.dataInicio).format('DD/MM/YYYY')} até ${Moment(dataFim).format('DD/MM/YYYY')}`;
  }

  checkItensAmount = (item) => {
    if (item.itemProduto && item.itemTela) {
      return item.itemProduto.length + item.itemTela.length;
    }
    return item.itemProduto ? item.itemProduto.length : item.itemTela.length;
  }

  getFiltersUsed = () => {
    const textFilters = {};
    const {
      cliente, situacao,
    } = this.props;

    textFilters.cliente = cliente ? `Cliente: ${cliente.label}` : 'Cliente: Todos';
    textFilters.situacao = situacao ? `Situação: ${situacao}` : 'Situação: Todos';
    textFilters.data = this.handlePeriod();

    return (
      Object.keys(textFilters).map((key, index) => (
        <Text key={index} style={styles.subitle}> {textFilters[key]};</Text>
      ))
    );
  }

  render() {
    return (
      <>
        {this.state.dataLoaded ?
          <PDFViewer style={{ width: '100%', height: '700px' }}>
            <Document>
              <Page style={{ paddingBottom: 25, paddingTop: 25 }} size="A4" orientation="landscape">
                <Text style={styles.title}>Relatório Fila de Produtos</Text>
                <Text style={styles.subitleFilter}>{this.getFiltersUsed()}</Text>
                <View style={styles.body}>
                  <View style={styles.table}>
                    <View fixed style={styles.tableRow}>
                      <View style={[styles.xlCol, styles.tableHeader]}>
                        <Text style={styles.tableCell}>Nome Cliente</Text>
                      </View>
                      <View style={[styles.xlCol, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Qt Produtos</Text>
                      </View>
                      <View style={[styles.xlCol, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Qt Telas</Text>
                      </View>
                      <View style={[styles.xlCol, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Valor Produtos</Text>
                      </View>
                      <View style={[styles.xlCol, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Valor Telas</Text>
                      </View>
                      <View style={[styles.xlCol, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Valor Total</Text>
                      </View>
                      <View style={[styles.xlCol, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Data Venda</Text>
                      </View>
                    </View>
                    {this.state.itensData.map((body, bodyidx) => {
                      const styleRow = [styles.tableRow];
                      if (!(bodyidx % 2)) {
                        styleRow.push(styles.style1);
                      }
                      return <View wrap={false} key={`${bodyidx} +_body`}
                        style={styleRow}>
                        <View style={[styles.tableCol, styles.xlCol]}>
                          <Text style={styles.tableCell}>{body.clienteNome}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlCol]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{body.itemProduto ? body.itemProduto.length : 0}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlCol]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{body.itemTela ? body.itemTela.length : 0}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlCol]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{money(body.valorTotalItemProd)}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlCol]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{money(body.valorTotalItemTela)}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlCol]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{money(body.valorTotalItem)}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlCol]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{Moment(body.dataCriacao).format('DD-MM-YYYY HH:mm')}</Text>
                        </View>
                      </View>
                    })}
                  </View>
                  <View style={styles.totalsBody}>
                    <View>
                      <Text style={styles.totalTitle}>Totais
                      </Text>
                      <Text style={styles.subitle}>Valor Pago: {money(this.state.itensData.reduce((acc, current) => acc + Number(current.valorTotalItem), 0).toFixed(2))}</Text>
                    </View>
                  </View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
          : <div style={{ textAlign: 'center' }}>
            <Spinner style={{
              width: '7rem',
              height: '7rem',
            }} color="primary" />
          </div>}
      </>
    )
  }
}

export default ImprimirItensPDF;
