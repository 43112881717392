import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from "@react-pdf/renderer";
import { Spinner } from "reactstrap";
import Roboto from "../../assets/fonts/Roboto-Bold.ttf";
import logo from "../../assets/img/LOGO GRADUS.png";
import Moment from "moment";

const styles = StyleSheet.create({
  title: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 21,
    textAlign: "center",
    fontFamily: "Roboto",
  },
  subitle: {
    marginTop: 15,
    marginLeft: 25,
    fontSize: 20,
    fontFamily: "Roboto",
  },
  textItem: {
    marginTop: 15,
    marginLeft: 25,
    fontSize: 16,
    fontFamily: "Roboto",
  },
  textValue: {
    marginTop: 10,
    marginLeft: 25,
    fontSize: 16,
  },
  lastSubitle: {
    marginBottom: 5,
    marginLeft: 25,
    fontSize: 12,
  },
  fotter1: {
    marginTop: 90,
    marginLeft: 25,
    fontSize: 12,
    textAlign: "center",
  },
  fotter2: {
    marginTop: 43,
    marginLeft: 260,
    fontSize: 13,
    textAlign: "center",
    borderTop: "1 solid black",
    width: "40%",
  },
  body: {
    flexGrow: 1,
    margin: 10,
  },
  bodyDescription: {
    marginLeft: 25,
    fontSize: 12,
    marginTop: 40,
    color: "#4d4d4d",
  },
  totalDescription: {
    marginLeft: 25,
    fontSize: 12,
  },
  underlineText: {
    textDecoration: "underline",
  },
  tableRowHeaderTop: {
    borderBottom: "1 solid black",
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 25,
  },
  tableRowHeaderBottom: {
    borderBottom: "1 solid black",
    flexDirection: "row",
    marginTop: 16,
    marginLeft: 25,
  },
  tableRowBody: {
    flexDirection: "row",
  },
  headText: {
    marginLeft: 25,
    fontSize: 12,
  },
  RigthValue: {
    width: "120px",
    position: "absolute",
    right: 0,
    textAlign: "right",
  },
  marginBottom: {
    marginBottom: 10,
  },
  alignCenter: {
    textAlign: "center",
  },
  totalValue: {
    marginTop: 10,
    fontSize: 17,
  },
  logoStyles: {
    width: "120px",
    marginLeft: 25,
  },
  imageStyles: {
    width: "500px",
    marginLeft: 25,
  },
  capitalize: {
    textTransform: "capitalize",
  },
});

const styles2 = StyleSheet.create({
  title: {
    marginLeft: 32,
    marginBottom: 5,
    fontSize: 17,
    textAlign: "left",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  totalTitle: {
    marginLeft: 5,
    marginBottom: 5,
    fontSize: 17,
    textAlign: "left",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  subitle: {
    marginLeft: 10,
    fontSize: 13,
    marginBottom: 10,
  },
  subitleFilter: {
    marginLeft: 32,
    fontSize: 13,
    marginBottom: 10,
  },
  body: {
    flexGrow: 1,
    margin: 10,
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
  },
  table: {
    display: "table",
    width: "auto",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    marginTop: 2,
    marginBottom: 4,
    paddingBottom: 4,
    paddingTop: 2,
  },
  style1: {
    backgroundColor: "#EFEFEF",
  },
  tableHeader: {
    color: "#6d6c6c",
    fontFamily: "Roboto",
    fontWeight: "bold",
    borderStyle: "solid",
    borderBottom: 1,
    textAlign: "left",
  },
  tableCol: {
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableCell: {
    margin: "auto",
    fontSize: 22,
    height: "auto",
    width: "100%",
  },
  xlCol: {
    width: "190px",
  },
  mdCol: {
    width: "70px",
  },
  smCol: {
    width: "80px",
  },
  totalsBody: {
    display: "flex",
    flexDirection: "row-reverse",
    margin: 30,
    marginRight: 60,
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
});

Font.register({ family: "Roboto", src: Roboto });

// const ReciboProduto = (props) => {
//   // const [dataLoaded, setDataLoaded] = useState(false);
//   // useEffect(() => {
//   //   setTimeout(() => {
//   //     setDataLoaded(true);
//   //   }, 400);
//   // }, []);

//   return dataLoaded ?  : ;
// };

// export default ReciboProduto;

const ReciboProduto = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
    setOpen(true);
    return () => setOpen(false);
  }, []);

  const produto = props.produto;
  const [itensTable] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])

  return (
    <>
      {open ? (
        <PDFViewer style={{ width: "100%", height: "500px" }}>
          <Document>
            <Page
              style={{ paddingBottom: 25, paddingTop: 25 }}
              size="A4"
              orientation="portrait"
              // wrap={false}
            >
              <View style={styles.body}>
                <Image src={logo} style={styles.logoStyles}></Image>
                <Text style={[styles.title, styles.underlineText]}>
                  FICHA DE PRODUÇÃO
                </Text>
                <View style={styles.tableRowHeaderTop}></View>
                <View>
                  <Text style={[styles.subitle, styles.marginBottom]}>
                    DESENHO
                  </Text>
                  <Image
                    src={props.desenho[0]}
                    style={[styles.imageStyles, styles.marginBottom]}
                  ></Image>
                </View>
                <View style={styles.tableRowHeaderTop}></View>
                <View>
                  <Text style={styles.subitle}>ESPECIFICAÇÕES</Text>
                  <Text style={styles.textItem}>Cliente:</Text>
                  <Text style={[styles.textValue, styles.capitalize]}>
                    {produto.clienteNome}
                  </Text>
                  <Text style={styles.textItem}>Nome do arquivo:</Text>
                  <Text style={styles.textValue}>{produto.nome}</Text>
                  <Text style={styles.textItem}>Pasta do arquivo:</Text>
                  <Text style={styles.textValue}> </Text>
                  <Text style={styles.textItem}>Tamanho:</Text>
                  <Text
                    style={styles.textValue}
                  >{`${produto.base} X ${produto.altura}`}</Text>
                  <Text style={styles.textItem}>Material:</Text>
                  <Text style={styles.textValue}>{produto.observacao}</Text>
                  <Text style={styles.textItem}>Quantidade:</Text>
                  <Text
                    style={styles.textValue}
                  >{`${produto.quantidade} quantidades`}</Text>
                  <Text style={styles.textItem}>Tamanho da folha:</Text>
                  <Text style={styles.textValue}> </Text>
                  <Text style={styles.textItem}>Peças por folha:</Text>
                  <Text style={styles.textValue}> </Text>
                  <Text style={styles.textItem}>Quantidade de folhas:</Text>
                  <Text style={styles.textValue}> </Text>
                  <Text style={styles.textItem}>Previsão de Entrega:</Text>
                  <Text style={styles.textValue}>
                    {produto.previsao_entrega
                      ? Moment(produto.previsao_entrega).format(
                          "DD/MM/YYYY HH:mm"
                        )
                      : ""}
                  </Text>
                  <Text style={styles.textItem}>Data Impressão Recibo:</Text>
                  <Text style={styles.textValue}>
                    {Moment().format("DD/MM/YYYY HH:mm")}
                  </Text>
                  <Text style={styles.textValue}> </Text>
                  <Text style={styles.textValue}> </Text>
                </View>
                <View style={styles2.table}>
                  <View fixed style={styles2.tableRow}>
                    <View style={[styles2.smCol, styles2.tableHeader]}>
                      <Text style={styles2.tableCell}>Linha</Text>
                    </View>
                    <View style={[styles2.xlCol, styles2.tableHeader]}>
                      <Text style={styles2.tableCell}>Movimentação</Text>
                    </View>
                    <View style={[styles2.xlCol, styles2.tableHeader]}>
                      <Text style={styles2.tableCell}>Data/Hora</Text>
                    </View>
                  </View>
                  {itensTable.map((body, bodyidx) => {
                    const styleRow = [styles2.tableRow];
                    if (!(bodyidx % 2)) {
                      styleRow.push(styles2.style1);
                    }
                    return (
                      <View
                        wrap={false}
                        key={`${bodyidx} +_body`}
                        style={styleRow}
                      >
                        <View style={[styles2.tableCol, styles2.smCol]}>
                          <Text style={styles2.tableCell}>{body}</Text>
                        </View>
                        <View style={[styles2.tableCol, styles2.xlCol]}>
                          <Text style={styles2.tableCell}></Text>
                        </View>
                        <View style={[styles2.tableCol, styles2.xlCol]}>
                          <Text
                            style={[styles2.tableCell, styles2.alignCenter]}
                          ></Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Spinner
            style={{
              width: "7rem",
              height: "7rem",
            }}
            color="primary"
          />
        </div>
      )}
    </>
  );
};

export default ReciboProduto;
