import Inicio from '../views/Inicio/PaginaInicial';
import Cliente from '../views/Clientes/Cliente';
import Produto from '../views/Produtos/Produto';
import Item from '../views/Item/Item';
import RelatorioProduto from '../views/Relatorios/FilaImpressao/RelatorioFilaImpressao';
import RelatorioFilaItens from '../views/Relatorios/FilaProdutos/RelatorioFilaItem';

const routes = [
  {
    path: "/home",
    name: "Início",
    icon: "fas fa-home",
    component: Inicio,
    visible: true,
    nameToCheck: 'home',
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: "fas fa-address-book",
    component: Cliente,
    visible: true,
    admin: true,
    nameToCheck: 'clientes',
  },
  {
    path: "/fila-de-impressao",
    name: "Fila de Impressão",
    icon: "fas fa-print",
    component: Produto,
    visible: true,
    nameToCheck: 'fila-de-impressao',
  },
  {
    path: "/fila-de-produtos",
    name: "Fila de Produtos",
    icon: "fas fa-stamp",
    component: Item,
    visible: true,
    nameToCheck: 'fila-de-produtos',
  },
  {
    path: "/relatorio-fila-impressao",
    name: "Relatório Impressão",
    icon: "fas fa-file-alt",
    component: RelatorioProduto,
    visible: true,
    admin: true,
    nameToCheck: 'relatorio-fila-impressao',
  },
  {
    path: "/relatorio-fila-itens",
    name: "Relatório Vendas",
    icon: "fas fa-file-invoice",
    component: RelatorioFilaItens,
    visible: true,
    admin: true,
    nameToCheck: 'relatorio-fila-itens',
  },
  {
    redirect: true, path: '/', pathTo: '/home', name: 'Home',
  },
];
export default routes;
