import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Form,
  Col,
  Row,
  Spinner,
  Input,
} from 'reactstrap';
import Button from 'components/CustomButton/CustomButton.jsx';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';

class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clienteSelected: props.filtersOptions.clienteSelected || null,
      documento: props.filtersOptions.documento || '',
      telefone: props.filtersOptions.telefone || '',
      contato: props.filtersOptions.contato || '',
      loadData: false,
    };
  }

  async componentDidMount() {
    await this.getClientes(this.props.usuarioId);
    this.setState({
      loadData: true,
    });
  }

  getClientes = async (usuarioId) => {
    const { data } = await this.props.api.getAllClients(usuarioId);
    const clientes = data.map((cliente) => ({ value: cliente.id, label: cliente.nome }));
    this.setState({
      clientes,
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSelectChange = (state) => (evt) => {
    this.setState({
      [state]: evt,
    });
  }

  handleSubmit = async (event) => {

    try {
      event.preventDefault();
      const filters = {};
      const filtersOptions = {};

      const { clienteSelected, documento,
        telefone, contato,
      } = this.state;

      if (clienteSelected && clienteSelected.value) {
        filters.id = clienteSelected.value;
        filtersOptions.clienteSelected = clienteSelected;
      }
      if (documento) {
        filters.documento = documento;
        filtersOptions.documento = documento;
      }
      if(telefone) {
        filters.telefone = this.clearString(telefone, true);
        filtersOptions.telefone = telefone;
      }
      if (contato) {
        filters.contato = contato && contato.toUpperCase();
        filtersOptions.contato = contato;
      }

      this.props.loadData(filters, filtersOptions);
      this.props.modal();
    } catch (err) {
      console.log(err);
    }
  }

  getCnpjCpfMask = (rawValue) => {
    const unmakedValue = rawValue.replace(/[^a-zA-Z0-9]/g, '');
    if (unmakedValue.length > 11) {
      return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    }
    return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  }

  getTelefoneMask = (rawValue) => {
    const unmakedValue = this.clearString(rawValue);
    if (unmakedValue.length > 12) {
      return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  clearString = (string, sendObject) => {
    let clearedString = string.replace(/[_|/|.|-]/g, '');
    if (sendObject) {
      // remove the parentheses
      clearedString = clearedString.replace(/[()|.| ]/g, '');
    }
    return clearedString;
  }

  render() {
    return (
      <>
        {this.state.loadData ? (
          <Form onSubmit={this.handleSubmit} >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="telefone">Telefone</Label>
                  <MaskedInput
                    mask={this.getTelefoneMask}
                    className="form-control upper-case-input"
                    guide
                    name="telefone"
                    id="telefone"
                    placeholder="Digite aqui"
                    onChange={this.handleInputChange}
                    defaultValue={this.state.telefone}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="contato">Contato</Label>
                  <Input
                    type="text"
                    name="contato"
                    id="contato"
                    onChange={this.handleInputChange}
                    placeholder="Digite aqui"
                    disabled={this.props.detail}
                    defaultValue={this.state.contato}
                    className="upper-case-input"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="cliente">Cliente</Label>
                  <Select
                    options={this.state.clientes}
                    value={this.state.clienteSelected && this.state.clientes
                      .filter((cliente) => cliente.value === this.state.clienteSelected.value)}
                    onChange={this.handleSelectChange('clienteSelected')}
                    placeholder="Selecione"
                    isDisabled={this.state.detail}
                    className="upper-case-input"
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="cnpj">CNPJ/CPF</Label>
                  <MaskedInput
                    mask={this.getCnpjCpfMask}
                    className="form-control upper-case-input"
                    guide
                    name="documento"
                    id="documento"
                    placeholder="Digite aqui"
                    defaultValue={this.state.documento}
                    onChange={this.handleInputChange}
                    disabled={this.props.detail}
                  />
                </FormGroup>
              </Col>
            </Row>
            {!this.props.detail && (
              <Button color="success" round type="submit">
                Filtrar  <i className="fas fa-filter"></i>
              </Button>
            )}
          </Form>
        ) : (
            <div style={{ textAlign: 'center' }}>
              <Spinner style={{
                width: '7rem',
                height: '7rem',
              }} color="primary" />
            </div>
          )}
      </>
    );
  }
}

export default Filtros;