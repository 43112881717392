import React from "react";
import Cookies from "js-cookie";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  FormGroup,
  Label,
  Input,
  Badge,
} from "reactstrap";
import NovoProdutoForm from "./NovoProdutoForm";
import Money from "../../utils/Money";
import Filtros from "./Filtros";
import Notification from "../../components/Notification/Notification";
import confirm from "reactstrap-confirm";
import Api from "../../config/Api";
import UploadDesenho from "./UploadDesenho";

export default class Produto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get("TOKEN"),
      usuarioId: Cookies.get("USERNAME"),
      produtos: [],
      produtosSelected: [],
      totalSelected: 0,
      produto: {},
      edit: false,
      produtoModal: false,
      filtered: false,
      filterModal: false,
      dataLoaded: false,
      offset: 0,
      count: 0,
      loading: false,
      filters: {},
    };

    this.api = new Api(
      this.state.token,
      this.state.usuarioId,
      "fila-de-impressao",
      (err) => {
        if (err.response.status === 401)
          this.props.history.push({
            pathname: "/login",
            state: { previousURL: this.props.location.pathname },
          });
        Cookies.remove("TOKEN");
        Cookies.remove("USUARIO");
        Cookies.remove("USERNAME");
        localStorage.clear();
        return Promise.reject(err);
      }
    );
  }

  async componentDidMount() {
    const payload = {
      situacao: "RECEBIDO",
    };
    await this.getProdutos(0, payload, {}, true);
  }

  getProdutos = async (
    offsetParams,
    payload,
    filtersOptionsParam,
    dataLoaded
  ) => {
    try {
      if (!dataLoaded) {
        this.setState({
          loadingMore: true,
        });
      }
      const newPayload = { ...payload };
      newPayload.userId = this.state.usuarioId;
      const offsetToUse = this.handleOffset(offsetParams, payload.situacao);

      const limit = payload.situacao !== "RECEBIDO" ? 10 : null;

      const { data } = await this.api.getProdutos(
        limit,
        offsetToUse,
        newPayload
      );

      let produtosConcat = [];
      if (offsetToUse !== 0 && offsetToUse !== null) {
        produtosConcat = produtosConcat.concat(this.state.produtos);
      }
      produtosConcat = produtosConcat.concat(data?.rows);

      const newOffset = offsetToUse + data?.rows?.length;
      this.setState({
        produtos: produtosConcat.sort((a, b) =>
          a.clienteNome.localeCompare(b.clienteNome)
        ),
        offset: newOffset,
        count: data.count,
        loadingMore: false,
        dataLoaded: true,
        filtersOptions: filtersOptionsParam || {},
        filters: payload,
        totalProdutos: data.indicadorTotal || 0,
        situacao: newPayload.situacao,
        currentUserAdmin: data.admin,
      });
    } catch (err) {
      console.log(err);
    }
  };

  openEditModal = async (productId) => {
    try {
      const { data } = await this.api.getSingleProduct(productId);
      this.setState({
        produtoModal: !this.state.produtoModal,
        produto: data,
        edit: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleOffset = (offsetParams, situacao) => {
    const offsetNotUndefined =
      offsetParams !== undefined && situacao !== "RECEBIDO";

    const situationDifferentRecebido = situacao !== "RECEBIDO";

    if (offsetNotUndefined) {
      return offsetParams;
    }

    if (situationDifferentRecebido) {
      return this.state.offset;
    }

    return null;
  };

  handleProductModal = () => {
    this.setState({
      produtoModal: !this.state.produtoModal,
      produto: {},
      edit: false,
    });
  };

  handleCancelProduct = async (productId) => {
    const confirmation = await confirm({
      title: "Atenção",
      message: `O produto será excluído. Tem certeza que deseja continuar?`,
      confirmText: "Sim",
      cancelText: "Não",
      confirmColor: "success",
    });
    if (confirmation) {
      const { data } = await this.api.deleteProduct(productId);
      if (data.status) {
        this.getProdutos(0, { situacao: this.state.situacao }, true);
        this.abrirNotificacaoSucesso(data.message);
      } else {
        this.abrirNotificacaoErro("Erro ao excluir produto");
      }
    }
  };

  handleLiberarProduto = async (productId) => {
    try {
      const confirmation = await confirm({
        title: "Atenção",
        message: `O produto será liberado. Tem certeza que deseja continuar?`,
        confirmText: "Sim",
        cancelText: "Não",
        confirmColor: "success",
      });
      if (confirmation) {
        const situacao = this.state.situacao;
        const { data } = await this.api.liberarProduto(productId, { situacao });
        await this.getProdutos(0, { situacao }, {}, true);
        if (data && data.status) {
          this.abrirNotificacaoSucesso("Produto liberado com sucesso!");
        }
      }
    } catch (err) {
      this.abrirNotificacaoErro("Erro ao liberar produto");
    }
  };

  handleStatusChange = (status) => {
    const payload = {
      situacao: status,
    };
    return this.setState(
      {
        dataLoaded: false,
        produtosSelected: [],
        totalSelected: 0,
      },
      () => this.getProdutos(0, payload)
    );
  };

  abrirNotificacaoSucesso = (message) =>
    Notification.createNotification(
      "success",
      message ? message : "Operação realizada com sucesso!"
    );

  abrirNotificacaoErro = (message) =>
    Notification.createNotification("error", message);

  toggleFilterModal = () => {
    this.setState({
      filterModal: !this.state.filterModal,
    });
  };

  removeFilter = () => {
    const payload = {
      situacao: this.state.situacao,
    };
    this.setState(
      {
        filtered: false,
        dataLoaded: false,
      },
      () => this.getProdutos(0, payload)
    );
  };

  handleFilteredCheck = () => {
    const { filters } = this.state;
    if (filters.situacao === "LIBERADO") {
      return Object.entries(filters).length > 3;
    }
    return Object.entries(this.state.filters).length > 2;
  };

  loadData = (offset, payload, filtersOptions) => {
    const newPayload = payload || this.state.filters;
    newPayload.situacao = this.state.situacao;
    const newFiltersOptions = filtersOptions || this.state.filtersOptions;
    this.setState(
      {
        filtered: payload || this.handleFilteredCheck() ? true : false,
        dataLoaded: !payload ? true : false,
      },
      () => this.getProdutos(offset, newPayload, newFiltersOptions)
    );
  };

  handleDecimalValue = (value) => {
    return value && Number(value).toFixed(2);
  };

  handleReloadGrid = () => {
    const payload = {
      situacao: this.state.situacao,
    };
    this.setState(
      {
        dataLoaded: false,
      },
      () => this.getProdutos(0, payload)
    );
  };

  handleProductTitle = (produto) => {
    const string = `Nome: ${produto.nome} \n Local arquivo: ${
      produto.localArquivo
    } \n Quantidade: ${produto.quantidade} \n Base:${produto.base} \n Altura: ${
      produto.altura
    } \n Canal recebimento: ${
      produto.canalRecebimento || "SISTEMA"
    } \n Observação:${produto.observacao}`;
    return string;
  };

  handleSelectAllChange = () => (evt) => {
    const value = evt.target.checked;

    const { produtos } = this.state;
    let newTotalSelected = 0;
    let newList = [];

    if (value) {
      newList = produtos;
      newTotalSelected = produtos.reduce(
        (acc, cv) => acc + Number(cv.valor_total),
        0
      );
    } else {
      newList = [];
      newTotalSelected = 0;
    }
    this.setState({
      produtosSelected: newList,
      totalSelected: newTotalSelected,
    });
  };

  addProductSelectedList = (product) => (evt) => {
    const value = evt.target.checked;
    const { produtosSelected, totalSelected } = this.state;
    let newList = [];
    let newTotalSelected = totalSelected;

    if (value) {
      newList = [...produtosSelected, product];
      newTotalSelected += Number(product.valor_total);
    } else {
      newList = produtosSelected.filter(
        (productSelected) => productSelected.id !== product.id
      );
      newTotalSelected -= Number(product.valor_total);
    }
    this.setState({
      produtosSelected: newList,
      totalSelected: newTotalSelected,
    });
  };

  handleMultipleProductsAction = (action) => {
    const { produtosSelected } = this.state;

    if (!produtosSelected.length) {
      return alert("Necessário selecionar um ou mais registros para esta ação");
    }

    switch (action) {
      case "release":
        this.releaseMultipleProducts(produtosSelected);
        break;
      default:
        break;
    }
  };

  releaseMultipleProducts = async (productsSelected) => {
    const { situacao } = this.state;
    await Promise.all(
      productsSelected.map(async (product) => {
        const { data } = await this.api.liberarProduto(product.id, {
          situacao,
        });
        if (!data.status) {
          return this.abrirNotificacaoErro("Erro ao liberar produto");
        }
      })
    );
    this.setState({
      produtosSelected: [],
      totalSelected: 0,
    });
    return this.getProdutos(0, { situacao }, {}, true);
  };

  gerarRecibo = async (produtoId) => {
    const produtoFind = this.state.produtos.find(
      (produto) => produto.id === produtoId
    );
    this.setState((prevState) => ({
      uploadPrintModal: !prevState.uploadPrintModal,
      produto: produtoFind,
    }));
  };

  togglePrintReport = () => {
    this.setState((prevState) => ({
      uploadPrintModal: !prevState.uploadPrintModal,
    }));
  }

  render() {
    return (
      <>
        <div className="content">
          {this.state.dataLoaded ? (
            <>
              <Row className="row-Stats">
                <Col xs={12} sm={6} md={6} lg={3} className="col-Stats">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col xs={5} md={4}>
                          <div className="icon-big text-center text-success">
                            <i className="fas fa-money-check-alt" />
                          </div>
                        </Col>
                        <Col xs={7} md={8}>
                          <div className="numbers">
                            <p className="card-category">Total</p>
                            <CardTitle tag="p">
                              {Money(this.state.totalProdutos)}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {this.state.situacao === "RECEBIDO" && (
                <button
                  className="btn btn-primary novo-cadastro-button-margin"
                  onClick={() => this.handleProductModal()}
                >
                  <i className="fas fa-folder-plus"></i> Novo Produto
                </button>
              )}
              {this.state.situacao !== "LIBERADO" &&
                this.state.currentUserAdmin && (
                  <UncontrolledDropdown group direction="right">
                    <DropdownToggle caret color="primary">
                      <i className="fas fa-check-double"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        title="Liberar"
                        onClick={() =>
                          this.handleMultipleProductsAction("release")
                        }
                      >
                        <i className="fas fa-check"></i> Liberar
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              <Row>
                <Col className="ml-auto mr-auto" md="11">
                  <Card className="card-upgrade">
                    <CardHeader className="text-center">
                      <Row>
                        <Col md="10">
                          <CardTitle tag="h4">
                            Fila Impressão{" "}
                            <UncontrolledDropdown group size="sm">
                              <DropdownToggle caret color="primary">
                                {this.state.situacao}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  title="Recebido"
                                  onClick={() =>
                                    this.handleStatusChange("RECEBIDO")
                                  }
                                >
                                  <i className="fas fa-receipt"></i> Recebido do
                                  cliente
                                </DropdownItem>
                                <DropdownItem
                                  title="Impresso"
                                  onClick={() =>
                                    this.handleStatusChange("IMPRESSO")
                                  }
                                >
                                  <i className="fas fa-print"></i> Arquivo
                                  impresso
                                </DropdownItem>
                                <DropdownItem
                                  title="Liberado"
                                  onClick={() =>
                                    this.handleStatusChange("LIBERADO")
                                  }
                                >
                                  <i className="fas fa-check"></i> Produto
                                  liberado
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </CardTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="flex">
                          <Button
                            className="filter-margin"
                            color="primary"
                            outline
                            onClick={this.toggleFilterModal}
                            size="sm"
                          >
                            <i className="fas fa-filter"></i> Filtros
                          </Button>
                          {this.state.filtered && (
                            <Button
                              color="primary"
                              outline
                              onClick={this.removeFilter}
                              size="sm"
                            >
                              <i className="fas fa-trash"></i> Limpar
                            </Button>
                          )}
                        </Col>
                        {!this.state.filtered && (
                          <Col md="1" className="reload-btn-margin">
                            <Button
                              color="primary"
                              outline
                              onClick={() => this.handleReloadGrid()}
                              disabled={this.state.situacao === "RECEBIDO"}
                              size="sm"
                            >
                              <i className="fas fa-redo"></i>
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.produtosSelected.length > 0 && (
                        <>
                          <div>
                            <span className="badge font-badge badge-main-color">
                              Marcados {this.state.produtosSelected.length}{" "}
                              produtos no total de{" "}
                              {Money(this.state.totalSelected)}
                            </span>
                          </div>
                        </>
                      )}
                      <Table responsive>
                        <thead className="text-primary">
                          <tr>
                            {this.state.currentUserAdmin && (
                              <th>
                                <FormGroup check>
                                  <Label
                                    id={"selectTableAll"}
                                    check
                                    className="selectTableRow"
                                  >
                                    <Input
                                      type="checkbox"
                                      id={"selectTable"}
                                      name={"selectTable"}
                                      // checked={this.handleCheckedInput()}
                                      onChange={this.handleSelectAllChange()}
                                    />
                                    <span className="form-check-sign">
                                      <span className="check"></span>
                                    </span>
                                  </Label>
                                </FormGroup>
                              </th>
                            )}
                            <th>Ações</th>
                            <th className="text-center">Arquivo</th>
                            <th className="text-center">Quantidade</th>
                            <th className="text-center">Tamanho</th>
                            <th className="text-center">Cliente</th>
                            <th className="text-center">Vlr Unitário</th>
                            <th className="text-center">Vlr Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.produtos.map((produto) => {
                            return (
                              <tr
                                title={this.handleProductTitle(produto)}
                                key={produto.id}
                              >
                                {this.state.currentUserAdmin && (
                                  <td>
                                    <FormGroup check>
                                      <Label
                                        id={`${produto.id}-selectTable`}
                                        check
                                        className="selectTableRow"
                                      >
                                        <Input
                                          type="checkbox"
                                          id={`${produto.id}-selectTable`}
                                          name={`${produto.id}-selectTable`}
                                          checked={this.state.produtosSelected.some(
                                            (prodSelected) =>
                                              prodSelected.id === produto.id
                                          )}
                                          onChange={this.addProductSelectedList(
                                            produto
                                          )}
                                        />
                                        <span className="form-check-sign">
                                          <span className="check"></span>
                                        </span>
                                      </Label>
                                    </FormGroup>
                                  </td>
                                )}
                                <td>
                                  <UncontrolledDropdown group>
                                    <DropdownToggle caret color="primary">
                                      <i className="fas fa-cog"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        title="Editar"
                                        onClick={() =>
                                          this.openEditModal(produto.id)
                                        }
                                      >
                                        <i className="fas fa-edit"></i> Editar
                                      </DropdownItem>
                                      {!this.props.liberados &&
                                        this.state.currentUserAdmin &&
                                        Number(produto.valor_total) !== 0 && (
                                          <DropdownItem
                                            title="Liberar"
                                            onClick={() =>
                                              this.handleLiberarProduto(
                                                produto.id
                                              )
                                            }
                                          >
                                            <i className="fas fa-check"></i>{" "}
                                            Liberar
                                          </DropdownItem>
                                        )}
                                      {this.state.currentUserAdmin && (
                                        <DropdownItem
                                          title="Recibo"
                                          onClick={() =>
                                            this.gerarRecibo(produto.id)
                                          }
                                        >
                                          <i className="fas fa-receipt"></i>{" "}
                                          Recibo
                                        </DropdownItem>
                                      )}
                                      <DropdownItem
                                        title="Excluir"
                                        onClick={() =>
                                          this.handleCancelProduct(produto.id)
                                        }
                                      >
                                        <i className="fas fa-trash"></i> Excluir
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                                <td className="text-center">{produto.nome}</td>
                                <td className="text-center">
                                  {produto.quantidade}
                                </td>
                                <td className="text-center">
                                  {`${this.handleDecimalValue(
                                    produto.base
                                  )} X ${this.handleDecimalValue(
                                    produto.altura
                                  )}`}
                                </td>
                                <td className="text-center">
                                  {produto.clienteNome}
                                </td>
                                <td className="text-center">
                                  {Money(produto.valor_unitario)}
                                </td>
                                <td className="text-center">
                                  {Money(produto.valor_total)}
                                </td>
                                {Number(produto.valor_total) === 0 &&
                                  this.state.currentUserAdmin && (
                                    <td>
                                      <Badge pill variant="success">
                                        Novo
                                      </Badge>
                                    </td>
                                  )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="show-more-center-table-pagined">
                      {this.state.loadingMore ? (
                        <div className="load-spinner-center-table-pagined">
                          <Spinner size="sm" color="primary" />{" "}
                        </div>
                      ) : (
                        <Button
                          color="primary"
                          id="mostrarMaisBtn"
                          outline
                          onClick={() => this.loadData()}
                          disabled={
                            this.state.produtos.length ===
                            parseInt(this.state.count)
                          }
                          size="sm"
                        >
                          Mostrar Mais
                        </Button>
                      )}
                    </div>
                    <div className="info-pagination-pagination">
                      <h3>
                        Mostrando{" "}
                        {this.state.produtos && this.state.produtos.length} de{" "}
                        {this.state.count} {"Produtos"}
                      </h3>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <div className="fila-impressao-spinner">
              <Spinner
                style={{
                  width: "7rem",
                  height: "7rem",
                }}
                color="primary"
              />
            </div>
          )}
        </div>
        <Modal
          className="new-expense-modal"
          isOpen={this.state.produtoModal}
          toggle={this.handleProductModal}
        >
          <ModalHeader toggle={this.handleProductModal}>Produto</ModalHeader>
          <ModalBody>
            <NovoProdutoForm
              produto={this.state.produto}
              edit={this.state.edit}
              api={this.api}
              loadData={this.getProdutos}
              modal={this.handleProductModal}
              abrirNotificacaoSucesso={this.abrirNotificacaoSucesso}
              abrirNotificacaoErro={this.abrirNotificacaoErro}
              usuarioId={this.state.usuarioId}
              situacao={this.state.situacao}
              currentUserAdmin={this.state.currentUserAdmin}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleProductModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className="new-expense-modal"
          isOpen={this.state.filterModal}
          toggle={this.toggleFilterModal}
        >
          <ModalHeader toggle={this.toggleFilterModal}>Filtros</ModalHeader>
          <ModalBody>
            <Filtros
              api={this.api}
              modal={this.toggleFilterModal}
              loadData={this.loadData}
              abriNotificacaoSucesso={this.abrirNotificacaoSucesso}
              usuarioId={this.state.usuarioId}
              filters={this.state.filters}
              filtersOptions={this.state.filtersOptions}
              situacao={this.state.situacao}
              currentUserAdmin={this.state.currentUserAdmin}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleFilterModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className="new-expense-modal"
          isOpen={this.state.uploadPrintModal}
          toggle={this.togglePrintReport}
        >
          <ModalHeader toggle={this.togglePrintReport}>Upload Desenho</ModalHeader>
          <ModalBody>
            <UploadDesenho
            produto={this.state.produto}
            abrirNotificacaoSucesso={this.abrirNotificacaoSucesso}
            abrirNotificacaoErro={this.abrirNotificacaoErro}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.togglePrintReport}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
