import React, { useState, useEffect } from 'react';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import Moment from 'moment';
import { Spinner } from 'reactstrap';
import Money from '../../utils/Money';
import Roboto from '../../assets/fonts/Roboto-Bold.ttf';

const styles = StyleSheet.create({
  title: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 21,
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  subitle: {
    marginTop: 15,
    marginLeft: 25,
    fontSize: 16,
  },
  lastSubitle: {
    marginBottom: 5,
    marginLeft: 25,
    fontSize: 12,
  },
  fotter1: {
    marginTop: 90,
    marginLeft: 25,
    fontSize: 12,
    textAlign: 'center',
  },
  fotter2: {
    marginTop: 43,
    marginLeft: 260,
    fontSize: 13,
    textAlign: 'center',
    borderTop: '1 solid black',
    width: '40%',
  },
  body: {
    flexGrow: 1,
    margin: 10,
  },
  bodyDescription: {
    marginLeft: 25,
    fontSize: 12,
    marginTop: 40,
    color: '#4d4d4d',
  },
  totalDescription: {
    marginLeft: 25,
    fontSize: 12,
  },
  underlineText: {
    textDecoration: 'underline',
  },
  table: {
    display: "table",
    width: "auto",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    marginTop: 2,
    marginBottom: 4,
    paddingBottom: 4,
    paddingTop: 2,
  },
  tableHeader: {
    color: '#6d6c6c',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    borderStyle: "solid",
    borderBottom: 1,
    textAlign: 'left',
  },
  tableCol: {
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'left'
  },
  tableCell: {
    margin: "auto",
    fontSize: 12,
    height: 'auto',
    width: '100%'
  },
  xlColProd: {
    width: "130 px",
  },
  xlColTela: {
    width: "80px",
  },
  mdCol: {
    width: "70px",
  },
  smCol: {
    width: "50px",
  },
  tableRowHeaderTop: {
    borderBottom: '1 solid black',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 25,
  },
  tableRowHeaderBottom: {
    borderBottom: '1 solid black',
    flexDirection: 'row',
    marginTop: 16,
    marginLeft: 25,
  },
  tableRowBody: {
    flexDirection: 'row',
  },
  headText: {
    marginLeft: 25,
    fontSize: 12,
  },
  RigthValue: {
    width: '120px',
    position: 'absolute',
    right: 0,
    textAlign: 'right'
  },
  marginBottom: {
    marginBottom: 20,
  },
  alignCenter: {
    textAlign: 'center'
  },
  totalValue: {
    marginTop: 10,
    fontSize: 17,
  }
});

Font.register({ family: 'Roboto', src: Roboto });

const ReciboItem = (props) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDataLoaded(true);
    }, 400);
  }, []);

  const venda = props.venda;
  return (
    dataLoaded ?
      <PDFViewer style={{ width: '100%', height: '500px' }}>
        <Document>
          <Page style={{ paddingBottom: 25, paddingTop: 25 }} size="A4" orientation="landscape">
            <View style={styles.body}>
              <Text style={[styles.title, styles.underlineText]}>Recibo de venda</Text>
              <View >
                <Text style={styles.subitle}>Dados da venda:</Text>
              </View>
              <View style={styles.tableRowHeaderTop}>
              </View>
              <View>
                <Text style={styles.headText}>{`Cliente: ${venda.clienteNome}`}</Text>
              </View>
              <View>
                <Text style={styles.headText}>{`Data da venda: ${Moment(venda.dataCriacao).format('DD/MM/YYYY HH:mm')}`}</Text>
              </View>
              <View>
                <Text style={[styles.headText, styles.totalValue]}>{`Valor total da venda: ${Money(venda.valorTotalItem)}`}</Text>
              </View>
              <View style={styles.tableRowHeaderTop}>
              </View>
              {venda.itemProduto && (
                <>
                  <View>
                    <Text style={[styles.subitle, styles.marginBottom]}>Produto(s):</Text>
                  </View>
                  <View style={styles.table}>
                    <View fixed style={styles.tableRow}>
                      <View style={[styles.xlColProd, styles.tableHeader]}>
                        <Text style={styles.tableCell}>Nome</Text>
                      </View>
                      <View style={[styles.xlColProd, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Unidade</Text>
                      </View>
                      <View style={[styles.xlColProd, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Quantidade</Text>
                      </View>
                      <View style={[styles.xlColProd, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Observação</Text>
                      </View>
                      <View style={[styles.xlColProd, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Valor unitário</Text>
                      </View>
                      <View style={[styles.xlColProd, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Valor total</Text>
                      </View>
                    </View>
                    {venda.itemProduto.map((item, idx) => {
                      const styleRow = [styles.tableRow];
                      if (!(item % 2)) {
                        styleRow.push(styles.style1);
                      }
                      return <View wrap={false} key={`${idx} +_item`}
                        style={styleRow}>
                        <View style={[styles.tableCol, styles.xlColProd]}>
                          <Text style={styles.tableCell}>{item.nome}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColProd]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.unidade}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColProd]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{parseFloat(item.quantidade)}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColProd]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.observacao}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColProd]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{Money(item.valorUnitario)}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColProd]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{Money(item.valorTotal)}</Text>
                        </View>
                      </View>
                    })}
                  </View>
                </>
              )}
              {venda.itemTela && (
                <>
                  <View>
                    <Text style={[styles.subitle, styles.marginBottom]}>Tela(s) de serigrafia:</Text>
                  </View>
                  <View style={styles.table}>
                    <View fixed style={styles.tableRow}>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={styles.tableCell}>Medidas</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Perfil</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Lavar</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Gravar</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Esticar</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Tecido</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Emulsão</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Observação</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Qt telas</Text>
                      </View>
                      <View style={[styles.xlColTela, styles.tableHeader]}>
                        <Text style={[styles.tableCell, styles.alignCenter]}>Valor Total</Text>
                      </View>
                    </View>
                    {venda.itemTela.map((item, idx) => {
                      const styleRow = [styles.tableRow];
                      if (!(item % 2)) {
                        styleRow.push(styles.style1);
                      }
                      return <View wrap={false} key={`${idx} +_item`}
                        style={styleRow}>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={styles.tableCell}>{`${Number(item.base).toFixed(2)} X ${Number(item.altura).toFixed(2)}`}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.perfil}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.lavar && 'X'}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.gravar && 'X'}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.esticar && 'X'}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.tecido}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.emulsao}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.observacao}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{item.quantidade}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.xlColTela]}>
                          <Text style={[styles.tableCell, styles.alignCenter]}>{Money(item.valorTotal)}</Text>
                        </View>
                      </View>
                    })}
                  </View>
                </>
              )}
              <Text style={styles.fotter1}>{`Caxias do Sul, ${Moment(venda.dataCriacao).format('DD/MM/YYYY')}`}</Text>
              <Text style={styles.fotter2}>{venda.clienteNome}</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      : <div style={{ textAlign: 'center' }}>
        <Spinner style={{
          width: '7rem',
          height: '7rem',
        }} color="primary" />
      </div>
  )
}

export default ReciboItem;