import React from "react";
import { FormGroup, Label, Input, Col, Row, Button } from "reactstrap";
import Select from "react-select";

const ItemProduto = (props) => {
  const unidadeList = [
    { value: "litro", label: "Litro" },
    { value: "quilograma", label: "Kilograma" },
    { value: "metroLinear", label: "Metro Linear" },
    { value: "unidade", label: "Unidade" },
  ];

  return (
    <>
      {props.produtos.map((itemProd, idx) => {
        return (
          <>
            <Row key={idx}>
              <Col md="2">
                <FormGroup>
                  <Label for="nome">Nome</Label>
                  <Input
                    type="text"
                    name="nome"
                    id="nome"
                    onChange={props.handleItemProdutoPropChange(idx, "nome")}
                    defaultValue={itemProd.nome && itemProd.nome.toUpperCase()}
                    className="upper-case-input"
                    placeholder="Digite aqui"
                    autoComplete="new-text"
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="quantidade">Quantidade</Label>
                  <Input
                    type="number"
                    name="quantidade"
                    id="quantidade"
                    className="upper-case-input"
                    placeholder="Digite aqui"
                    defaultValue={Number(itemProd.quantidade).toFixed(2)}
                    onChange={props.handleItemProdutoPropValueChange(
                      idx,
                      "quantidade"
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="observacao">Observação</Label>
                  <Input
                    type="textarea"
                    onChange={props.handleItemProdutoPropChange(
                      idx,
                      "observacao"
                    )}
                    name="observacao"
                    id="observacao"
                    defaultValue={itemProd.observacao}
                  />
                </FormGroup>
              </Col>
              <Button
                color="primary"
                onClick={props.handleRemoveItemProduto(idx)}
                className="btn-round btn-icon dynamic-minus-button"
              >
                <i className="fa fa-minus"></i>
              </Button>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label for="unidade">Unidade</Label>
                  <Select
                    options={unidadeList}
                    value={unidadeList.filter(
                      (uni) => uni.value === itemProd.unidade
                    )}
                    onChange={props.handleItemProdutoPropChange(idx, "unidade")}
                    placeholder="Selecione"
                    className="upper-case-input"
                  />
                </FormGroup>
              </Col>
              {props.currentUserAdmin && (
                <>
                  <Col md="2">
                    <FormGroup>
                      <Label for="valorUnitario">Valor Unitário</Label>
                      <Input
                        type="number"
                        name="valorUnitario"
                        id="valorUnitario"
                        placeholder="Digite aqui"
                        className="upper-case-input"
                        defaultValue={Number(itemProd.valorUnitario).toFixed(2)}
                        onChange={props.handleItemProdutoPropValueChange(
                          idx,
                          "valorUnitario"
                        )}
                        step="0.001"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label for="valorTotal">Valor Total</Label>
                      <Input
                        type="number"
                        name="valorTotal"
                        id="valorTotal"
                        placeholder="Digite aqui"
                        className="upper-case-input"
                        value={Number(itemProd.valorTotal).toFixed(2)}
                        onChange={props.handleItemProdutoPropValueChange(
                          idx,
                          "valorTotal"
                        )}
                        disabled
                        step="0.001"
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
            </Row>
          </>
        );
      })}
      <Button color="primary" onClick={() => props.handleAddItemProduto()}>
        <i className="fa fa-plus"></i> Produto
      </Button>
    </>
  );
};

export default ItemProduto;
