import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Form,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import Button from 'components/CustomButton/CustomButton.jsx';
import Select from 'react-select';

class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInicio: props.filtersOptions.dataInicio || '',
      dataFim: props.filtersOptions.dataFim
        ? props.filtersOptions.dataFim : '',
      clienteSelected: props.filtersOptions.clienteSelected
        ? props.filtersOptions.clienteSelected : null,
      loadData: false,
    };
  }

  async componentDidMount() {
    await this.getClientes(this.props.usuarioId);
    this.setState({
      loadData: true,
    });
  }

  getClientes = async (usuarioId) => {
    const { data } = await this.props.api.getAllClients(usuarioId);
    const clientes = data.map((cliente) => ({ value: cliente.id, label: cliente.nome }));
    this.setState({
      clientes,
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSelectChange = (state) => (evt) => {
    this.setState({
      [state]: evt,
    });
  }

  handleSubmit = async (event) => {

    try {
      event.preventDefault();
      const filters = {};
      const filtersOptions = {};

      const { clienteSelected, dataInicio, dataFim, arquivo } = this.state;

      if (clienteSelected && clienteSelected.value) {
        filters.clienteId = clienteSelected.value;
        filtersOptions.clienteSelected = clienteSelected;
      }

      if (dataInicio) {
        if (this.props.situacao !== 'LIBERADO') {
          filters.dataInicio = dataInicio;
        } else {
          filters.dataInicioRelatorio = dataInicio;
        }
        filtersOptions.dataInicio = dataInicio;
      }
      if (dataFim) {
        if (this.props.situacao !== 'LIBERADO') {
          filters.dataFim = dataFim;
        } else {
          filters.dataFimRelatorio = dataFim
        }
        filtersOptions.dataFim = dataFim;
      }
      if (arquivo) {
        filters.arquivo = arquivo;
        filtersOptions.arquivo = arquivo;
      }
      filters.situacao = this.props.situacao;

      this.props.loadData(0, filters, filtersOptions);
      this.props.modal();
    } catch (err) {
      console.log(err);
    }
  }

  handleDefaultValue = (param) => {
    if (param === 1) {
      return this.state.tipoContaOptions
        .find((tipoConta) => tipoConta.value === this.state.tipoConta);
    } else {
      return this.state.enquadramentos
        .find((enquadramento) => enquadramento.value === this.state.enquadramentoDRE);
    }
  }

  render() {
    return (
      <>
        {this.state.loadData ? (
          <Form onSubmit={this.handleSubmit} >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="dataInicio">De</Label>
                  <Input
                    type="date"
                    name="dataInicio"
                    id="dataInicio"
                    value={this.state.dataInicio}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="dataFim">Até</Label>
                  <Input
                    type="date"
                    name="dataFim"
                    id="dataFim"
                    placeholder="Digite aqui"
                    value={this.state.dataFim}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="cliente">Cliente</Label>
                  <Select
                    options={this.state.clientes}
                    value={this.state.clienteSelected && this.state.clientes
                      .filter((cliente) => cliente.value === this.state.clienteSelected.value)}
                    onChange={this.handleSelectChange('clienteSelected')}
                    placeholder="Selecione"
                    isDisabled={this.state.detail}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="arquivo">Arquivo</Label>
                  <Input
                    type="text"
                    name="arquivo"
                    id="arquivo"
                    onChange={this.handleInputChange}
                    placeholder="Digite aqui"
                    disabled={this.props.detail}
                    defaultValue={this.state.arquivo}
                    className="upper-case-input"
                  />
                </FormGroup>
              </Col>
            </Row>
            {!this.props.detail && (
              <Button color="success" round type="submit">
                Filtrar  <i className="fas fa-filter"></i>
              </Button>
            )}
          </Form>
        ) : (
            <div style={{ textAlign: 'center' }}>
              <Spinner style={{
                width: '7rem',
                height: '7rem',
              }} color="primary" />
            </div>
          )}
      </>
    );
  }
}

export default Filtros;