import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import './index.css'

// import logo from "logo.svg";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  // renderParentRoute = (route, key) => {
  //   return (
  //     // <>
  //     <li>
  //       {/* <div className='routes-panel'> */}
  //         {/* <div className="routes-group"> */}
  //           <p>{route.name}</p>
  //           <div className="subroutes-separator"></div>
  //           <ul>
  //             {route.subRoutes &&
  //               route.subRoutes.map((subRoute, key) => {

  //                 return (
  //                   < li className={this.activeRoute(subRoute.path) + (subRoute.pro ? ' active-pro' : '')}
  //                     key={key}

  //                   >
  //                     <NavLink
  //                       to={subRoute.path}
  //                       className="nav-link"
  //                     >
  //                       <i className={subRoute.icon} />
  //                       <p>{subRoute.name}</p>
  //                     </NavLink>
  //                   </li>
  //                 )
  //               })}
  //           </ul>
  //         {/* </div> */}
  //       {/* </div > */}
  //     </li>
  //   )
  // }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          {/* <a
            href="https://www.creative-tim.com"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a> */}
          <a
            href="/home"
            className="simple-text logo-normal"
          >
            FILA DE IMPRESSÃO <i className="far fa-registered registered-icon-inicial" />
          </a>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;
              if (prop.visible) {
                return (
                  <li
                    className={
                      this.activeRoute(prop.path) +
                      (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              }
              return null;
              // return this.renderParentRoute(prop, key)
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
