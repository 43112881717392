import React, { useState } from 'react';
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import CustomCollapse from '../Collapse/CustomCollapse';
import DateHelper from '../../utils/Date';
import Select from 'react-select';

function PeriodSelect(props) {
  const [currentDateInitial] = useState('');
  const [currentDateEnd] = useState('');
  const [customPeriod, setCustomPeriod] = useState(false);
  const [monthList] = useState([
    { value: 1, label: 'Mês atual' },
    { value: 2, label: 'Mês anterior' },
    { value: 3, label: 'Período' },
  ]);

  const payloadCurrentMonth = () => {
    const startOfMonth = DateHelper.currentMonth().startOfMonth;
    const endOfMonth = DateHelper.currentMonth().endOfMonth;
    const destinoUm = props.dataUm;
    const destinoDois = props.dataDois;
    const payload = {
      startOfMonth,
      endOfMonth,
      destinoUm,
      destinoDois
    }
    return props.handlePeriod(payload)
  };

  const payloadLastMonth = () => {
    const startOfMonth = DateHelper.lastMonth().startOfMonth;
    const endOfMonth = DateHelper.lastMonth().endOfMonth;
    const destinoUm = props.dataUm;
    const destinoDois = props.dataDois;
    const payload = {
      startOfMonth,
      endOfMonth,
      destinoUm,
      destinoDois
    }
    return props.handlePeriod(payload)
  };

  const handleDefaultValue = (props) => {
    const result = props ? '' :
      monthList.filter(option => option.label === 'Mês atual');
    return result;
  };

  const handleSelectMonths = (evt) => {
    const value = evt ? evt.value : '';
    if (value !== 3) {
      setCustomPeriod(false);
    }
    if (value === 1) {
      payloadCurrentMonth();
      return;
    } else if (value === 2) {
      payloadLastMonth();
      return;
    } else if (value === 3) {
      if (customPeriod) {
        return;
      }
      setCustomPeriod(true);
      const startOfMonth = '';
      const endOfMonth = '';
      const destinoUm = props.dataUm;
      const destinoDois = props.dataDois;
      const payload = {
        startOfMonth,
        endOfMonth,
        destinoUm,
        destinoDois
      }
      return props.handlePeriod(payload);
    }
  };

  return (
    <CustomCollapse
      titulo={props.titulo}
      renderOpen={props.renderOpen}
    >
      <Row>
        <Col md="6">
          <FormGroup className="space-card-filter">
            <Select
              options={monthList}
              onChange={handleSelectMonths}
              defaultValue={handleDefaultValue(props.withoutDefaultValue)}
              placeholder="Selecione"
            >
            </Select>
          </FormGroup>
        </Col>
      </Row>
      {customPeriod && (
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for={props.dataUm}>De:</Label>
              <Input
                type="date"
                name={props.dataUm}
                id={props.dataUm}
                onChange={props.handleInputChange}
                defaultValue={currentDateInitial}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="dataVencimentoFim">Até:</Label>
              <Input
                type="date"
                name={props.dataDois}
                id={props.dataDois}
                onChange={props.handleInputChange}
                defaultValue={currentDateEnd}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </CustomCollapse>
  );
}

export default PeriodSelect;