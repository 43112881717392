import React from 'react';
import {
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Button,
} from "reactstrap";

const ItemTela = (props) => {
  return (
    <>
      {props.telas.map((itemTela, idx) => {
        return (
          <>
            <br />
            <Row key={`${idx} +itemTela`}>
              <Col md="2">
                <FormGroup>
                  <Label for="base">Base</Label>
                  <Input
                    type="number"
                    name="base"
                    id="base"
                    placeholder="Digite aqui"
                    className="upper-case-input"
                    defaultValue={itemTela.base ? Number(itemTela.base).toFixed(2) : null}
                    onChange={props.handleItemTelaPropChange(idx, 'base')}
                    step="0.001"
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="altura">Altura</Label>
                  <Input
                    type="number"
                    name="altura"
                    id="altura"
                    placeholder="Digite aqui"
                    className="upper-case-input"
                    defaultValue={itemTela.altura ? Number(itemTela.altura).toFixed(2) : null}
                    onChange={props.handleItemTelaPropChange(idx, 'altura')}
                    step="0.001"
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="perfil">Perfil</Label>
                  <Input
                    type="text"
                    name="perfil"
                    id="perfil"
                    onChange={props.handleItemTelaPropChange(idx, 'perfil')}
                    defaultValue={itemTela.perfil && itemTela.perfil.toUpperCase()}
                    className="upper-case-input"
                    placeholder="Digite aqui"
                    autoComplete="new-text"
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                < FormGroup check >
                  <Label check>
                    <Input type="checkbox"
                      name="lavar"
                      onChange={props.handleItemTelaPropChange(idx, 'lavar')}
                      defaultChecked={itemTela.lavar}
                    />{' '}
                    Lavar
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
                < FormGroup check >
                  <Label check>
                    <Input type="checkbox"
                      name="gravar"
                      onChange={props.handleItemTelaPropChange(idx, 'gravar')}
                      defaultChecked={itemTela.gravar}
                    />{' '}
                    Gravar
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
                < FormGroup check >
                  <Label check>
                    <Input type="checkbox"
                      name="esticar"
                      onChange={props.handleItemTelaPropChange(idx, 'esticar')}
                      defaultChecked={itemTela.esticar}
                    />{' '}
                    Esticar
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="tecido">Tecido</Label>
                  <Input
                    type="text"
                    name="tecido"
                    id="tecido"
                    onChange={props.handleItemTelaPropChange(idx, 'tecido')}
                    defaultValue={itemTela.tecido && itemTela.tecido.toUpperCase()}
                    className="upper-case-input"
                    placeholder="Digite aqui"
                    autoComplete="new-text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label for="emulsao">Emulsão</Label>
                  <Input
                    type="text"
                    name="emulsao"
                    id="emulsao"
                    onChange={props.handleItemTelaPropChange(idx, 'emulsao')}
                    defaultValue={itemTela.emulsao && itemTela.emulsao.toUpperCase()}
                    className="upper-case-input"
                    placeholder="Digite aqui"
                    autoComplete="new-text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="observacao">Observação</Label>
                  <Input
                    type="textarea"
                    onChange={props.handleItemTelaPropChange(idx, 'observacao')}
                    name="observacao"
                    id="observacao"
                    defaultValue={itemTela.observacao}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="quantidade">Quantidade</Label>
                  <Input
                    type="number"
                    name="quantidade"
                    id="quantidade"
                    placeholder="Digite aqui"
                    className="upper-case-input"
                    defaultValue={itemTela.quantidade && Number(itemTela.quantidade).toFixed(2)}
                    onChange={props.handleItemTelaPropChange(idx, 'quantidade')}
                  />
                </FormGroup>
              </Col>
              {props.currentUserAdmin && (
                <Col md="2">
                  <FormGroup>
                    <Label for="valorTotal">Valor Total</Label>
                    <Input
                      type="number"
                      name="valorTotal"
                      id="valorTotal"
                      placeholder="Digite aqui"
                      className="upper-case-input"
                      defaultValue={itemTela.valorTotal && Number(itemTela.valorTotal).toFixed(2)}
                      onChange={props.handleItemTelaPropChange(idx, 'valorTotal')}
                      step="0.001"
                    />
                  </FormGroup>
                </Col>
              )}
              <Button color="primary" fab
                round
                icon
                onClick={props.handleRemoveItemTela(idx)}
                className="btn-round btn-icon dynamic-minus-button"
              >
                <i className="fa fa-minus"></i>
              </Button>
            </Row>
          </>
        )
      })}
      <Button color="primary"
        onClick={() => props.handleAddItemTela()}
      >
        <i className="fa fa-plus"></i> Tela Serigrafia
      </Button>
    </>
  )
}

export default ItemTela;