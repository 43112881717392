import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Dropzone from "../../components/Dropzone/DropZone";
import CustomButton from "../../components/CustomButton/CustomButton";
import Base64Converter from "../../utils/Base64Converter";
import ReciboProduto from "./ReciboProduto";

const UploadDesenho = (props) => {
  const [arquivo, setArquivo] = useState("");
  const [printReportModal, setPrintReportModal] = useState(false);

  const togglePrintReport = () => {
    setPrintReportModal(!printReportModal);
  };

  const handleFilesUpload = async (files) => {
    if (!files.length) {
      return props.abrirNotificacaoErro("Formato de arquivo inválido!");
    }
    const base64StringsFiles = await Promise.all(
      files.map((file) => Base64Converter.convert(file))
    );

    const fileName = files[0]?.path;
    const fileExtension = fileName.substr(fileName.indexOf("."));

    const fileType = files[0]?.type || fileExtension;

    const validTypes = ["image/png", "image/jpeg"];

    setArquivo(base64StringsFiles);

    if (!validTypes.includes(fileType)) {
      props.abrirNotificacaoErro("Formato de arquivo inválido!");
    }
  };

  return (
    <>
      <Dropzone
        maxSize={10000000} // 10mb
        acceptedFormats={["image/*"]}
        onSubmit={handleFilesUpload}
        maxFiles={1}
        validExtensions={["png", "jpeg"]}
      >
        <div>
          <CustomButton type="button" id="file-avatar">
            <i className="fa fa-file-image" /> Selecione o arquivo
          </CustomButton>
          <span style={{ marginLeft: "3rem" }}>Ou arraste...</span>
        </div>
      </Dropzone>
      <div style={{ textAlign: "center" }}>
        <img src={arquivo} alt="" width="500" />
      </div>
      <Button
        color="primary"
        type="button"
        onClick={togglePrintReport}
        disabled={!arquivo}
        style={{ marginTop: "50px" }}
      >
        Gerar PDF <i className="fas fa-file-pdf"></i>
      </Button>

      <Modal
        className="new-expense-modal"
        isOpen={printReportModal}
        toggle={togglePrintReport}
      >
        <ModalHeader toggle={togglePrintReport}>Imprimir relatorio</ModalHeader>
        <ModalBody>
          <ReciboProduto produto={props.produto} desenho={arquivo} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePrintReport}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UploadDesenho;
