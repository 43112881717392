import React from "react";
import { FormGroup, Label, Col, Button } from "reactstrap";
import MaskedInput from "react-text-mask";
import confirm from "reactstrap-confirm";

const CpfCnpj = (props) => {
  const getCnpjCpfMask = (rawValue) => {
    const unmakedValue = rawValue.replace(/[^a-zA-Z0-9]/g, "");
    if (unmakedValue.length > 11) {
      return [
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ];
    }
    return [
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
    ];
  };

  const handleDocumento1Change = async (event) => {
    const unmaskedDocumento = event.target.value.replace(/[_|/|.|-]/g, "");

    if (unmaskedDocumento.length === 14) {
      handleAsDocument(unmaskedDocumento);
    } else if (unmaskedDocumento.length === 11) {
      handleAsDocument(unmaskedDocumento);
    }
  };

  const handleAsDocument = async () => {
    const { data } = await props.api.getClinteByDocument(
      props.documento,
      props.usuarioId,
      props.loginView
    );
    if (data.status && !props.loginView) {
      props.abrirNotificacaoErro(`Documento já cadastrado.`);
    }
    if (data.client && props.loginView) {
      const confirmation = await confirm({
        title: "Atenção",
        message: `Cliente encontrado na base de dados. Deseja utilizar esses dados?`,
        confirmText: "Sim",
        cancelText: "Não",
        confirmColor: "success",
      });
      if (confirmation) {
        props.setClientExistingData(data.client);
      }
      props.setClientExistingData({});
    }
    props.setClientExistingData({});
  };

  return (
    <>
      <Col md="6">
        <FormGroup>
          <Label for="cnpj">CNPJ/CPF</Label>
          <MaskedInput
            mask={getCnpjCpfMask}
            className="form-control upper-case-input"
            guide
            required
            name="documento"
            id="documento"
            placeholder="Digite aqui"
            defaultValue={props?.documento}
            onChange={props.handleInputChange}
            onBlur={handleDocumento1Change}
            disabled={props.detail}
          />
        </FormGroup>
      </Col>
      <Col>
        <Button className="custom-margim-top3" color="primary" type="button" onClick={handleDocumento1Change}>Verificar</Button>
      </Col>
    </>
  );
};

export default CpfCnpj;
