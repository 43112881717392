import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

const PaginaInicial = () => {
  return (
    <>
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" md="11">
            <Card className="card-upgrade">
              <CardHeader className="text-center">
                <CardTitle>
                  <h4>
                    Seja bem-vindo ao gerenciador da sua Fila de Impressão <i className="far fa-registered registered-icon-inicial" />
                  </h4>
                </CardTitle>
              </CardHeader>
              <CardBody>
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PaginaInicial;
