import React from "react";
import Cookies from 'js-cookie';

import "./style.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Api from '../../config/Api';
import NovoUsuarioForm from './NovoClienteForm';
import Filtros from './Filtros';
import Notification from '../../components/Notification/Notification';
import confirm from 'reactstrap-confirm';

export default class Cliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('TOKEN'),
      usuarioId: Cookies.get('USERNAME'),
      clientes: [],
      cliente: {},
      edit: false,
      clienteModal: false,
      filtered: false,
      filterModal: false,
      offset: 0,
      count: 0,
      loading: false,
      dataLoaded: false,
      filters: {},
      filtersOptions: {},
    };

    this.api = new Api(this.state.token, this.state.usuarioId, 'clientes', (err) => {
      if (err.response.status === 401) {
        this.props.history.push({
          pathname: '/login',
          state: { previousURL: this.props.location.pathname }
        });
        Cookies.remove('TOKEN');
        Cookies.remove('USUARIO');
        Cookies.remove('USERNAME');
        localStorage.clear();
        return Promise.reject(err);
      }
    });
  }

  async componentDidMount() {
    await this.getClientes();
    this.setState({
      dataLoaded: true,
    })
  }

  getClientes = async (offsetParams, payload, filtersOptionsParam) => {
    try {
      this.setState({
        loading: true,
      });
      const newPayload = { ...payload };
      newPayload.userId = this.state.usuarioId;
      const offsetToUse = offsetParams !== undefined ? offsetParams : this.state.offset;

      const { data } = await this.api.getClientes(10, offsetToUse, newPayload);

      let clientesConcat = [];

      if (offsetToUse !== 0) {
        clientesConcat = clientesConcat.concat(this.state.clientes);
      }
      clientesConcat = clientesConcat.concat(data.rows);

      const newOffset = offsetToUse + data.rows.length;
      this.setState({
        clientes: clientesConcat,
        offset: newOffset,
        count: data.count,
        loading: false,
        dataLoaded: true,
        filtersOptions: filtersOptionsParam || {},
        filters: payload,
      });
    } catch (err) {
      console.log(err);
    }
  }

  openEditModal = async (clienteId) => {
    try {
      const { data } = await this.api.getSingleClient(clienteId);
      this.setState({
        clienteModal: !this.state.clienteModal,
        cliente: data,
        edit: true
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleClientModal = () => {
    this.setState({
      clienteModal: !this.state.clienteModal,
      cliente: {},
      edit: false,
    })
  }

  handleCancelClient = async (clienteId) => {
    const confirmation = await confirm({
      title: 'Atenção',
      message: `O cliente será excluído. Tem certeza que deseja continuar?`,
      confirmText: 'Sim',
      cancelText: 'Não',
      confirmColor: "success",
    });
    if (confirmation) {
      const { data } = await this.api.deleteClient(clienteId);
      if (data.status) {
        this.getClientes(0);
        this.abrirNotificacaoSucesso(data.message)
      } else {
        this.abrirNotificacaoErro(data.message);
      }
    }
  }

  handleUsernameString = (string) => {
    let maskedString = string.replace(/^(\d{2})(\d)/g, '($1) $2');
    maskedString = maskedString.replace(/(\d)(\d{4})$/, '$1-$2');
    return maskedString;
  }

  abrirNotificacaoSucesso = (message) => Notification
    .createNotification('success', message ? message : 'Operação realizada com sucesso!');

  abrirNotificacaoErro = (message) => Notification
    .createNotification('error', message);

  abrirNotificacaoInfo = (message) => Notification
    .createNotification('info', message);

  toggleFilterModal = () => {
    this.setState({
      filterModal: !this.state.filterModal,
    });
  }

  removeFilter = () => {
    this.setState({
      filtered: false,
      dataLoaded: false,
    }, () => this.getClientes(0));
  }

  loadData = (payload, filtersOptions) => {
    this.setState({
      filtered: true,
      dataLoaded: false,
    }, () => this.getClientes(0, payload, filtersOptions));
  }

  plataformaUserId = (cliente) => {
    const styleObj = { height: "20px", marginTop: "2%", marginRight: "10px" };
    if (!cliente.plataformaUserId) {
      return (
        <span className="badge font-badge badge-red" style={styleObj}>
          Não
        </span>
      )
    }
    return (
      <span className="badge badge-success font-badge" style={styleObj}>
        Sim
      </span>
    )
  }

  render() {
    return (
      <>
        <div className="content">
          {this.state.dataLoaded ? (
            <>
              <button
                className="btn btn-primary novo-cadastro-button-margin"
                onClick={() => this.handleClientModal()}
              >
                <i className="fas fa-folder-plus"></i> Novo Cliente
            </button>
              <Row>
                <Col className="ml-auto mr-auto" md="11">
                  <Card className="card-upgrade">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Clientes
            </CardTitle>
                      <Row>
                        <Col className="flex">
                          <Button className="filter-margin" color="primary" outline onClick={this.toggleFilterModal} size="sm">
                            <i className="fas fa-filter"></i> Filtros
                      </Button>
                          {this.state.filtered && (
                            <Button color="primary" outline onClick={this.removeFilter} size="sm">
                              <i className="fas fa-trash"></i> Limpar
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Table responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Ações</th>
                            <th>Nome</th>
                            <th>Telefone 1</th>
                            <th>Telefone 2</th>
                            <th>Documento</th>
                            <th>Contato</th>
                            <th>Possui Usuáiro</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.clientes.map((cliente) => {
                            return (
                              <tr key={cliente.id}>
                                <td>
                                  <UncontrolledDropdown group>
                                    <DropdownToggle caret color="primary">
                                      <i className="fas fa-cog"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        title="Editar"
                                        onClick={() => this.openEditModal(cliente.id)}
                                      >
                                        <i className="fas fa-edit"></i> Editar
                                      </DropdownItem>
                                      <DropdownItem
                                        title="Editar"
                                        onClick={() => this.handleCancelClient(cliente.id)}
                                      >
                                        <i className="fas fa-trash"></i> Excluir
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                                <td>
                                  {cliente.nome}
                                </td>
                                <td>
                                  {this.handleUsernameString(cliente.telefone1)}
                                </td>
                                <td>
                                  {cliente.telefone2 && this.handleUsernameString(cliente.telefone2)}
                                </td>
                                <td>
                                  {cliente.documento}
                                </td>
                                <td>
                                  {cliente.contato}
                                </td>
                                <td>
                                  {this.plataformaUserId(cliente)}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="show-more-center-table-pagined">
                      {this.state.loading ? (
                        <div className="load-spinner-center-table-pagined">
                          <Spinner size="sm" color="primary" />
                          {' '}
                        </div>
                      ) :
                        <Button color="primary"
                          id="mostrarMaisBtn"
                          outline
                          onClick={() => this.getClientes()}
                          disabled={this.state.clientes.length === parseInt(this.state.count)}
                          size="sm">
                          Mostrar Mais
                        </Button>}
                    </div>
                    <div className="info-pagination-pagination">
                      <h3>
                        Mostrando
                        {' '}
                        {this.state.clientes && this.state.clientes.length}
                        {' '}
                        de
                        {' '}
                        {this.state.count}
                        {' '}
                        {'Clientes'}
                      </h3>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <div className="fila-impressao-spinner">
              <Spinner style={{
                width: '7rem',
                height: '7rem',
              }} color="primary" />
            </div>
          )
          }
        </div >
        <Modal className="new-expense-modal" isOpen={this.state.clienteModal} toggle={this.handleClientModal}>
          <ModalHeader toggle={this.handleClientModal}>Cliente</ModalHeader>
          <ModalBody>
            <NovoUsuarioForm
              cliente={this.state.cliente}
              edit={this.state.edit}
              api={this.api}
              loadData={this.getClientes}
              modal={this.handleClientModal}
              abrirNotificacaoSucesso={this.abrirNotificacaoSucesso}
              abrirNotificacaoErro={this.abrirNotificacaoErro}
              abrirNotificacaoInfo={this.abrirNotificacaoInfo}
              usuarioId={this.state.usuarioId}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleClientModal}>Fechar</Button>
          </ModalFooter>
        </Modal>
        <Modal className="new-expense-modal" isOpen={this.state.filterModal} toggle={this.toggleFilterModal}>
          <ModalHeader toggle={this.toggleFilterModal}>Filtros</ModalHeader>
          <ModalBody>
            <Filtros
              api={this.api}
              modal={this.toggleFilterModal}
              loadData={this.loadData}
              abriNotificacaoSucesso={this.abriNotificacaoSucesso}
              usuarioId={this.state.usuarioId}
              filters={this.state.filters}
              filtersOptions={this.state.filtersOptions}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleFilterModal}>Fechar</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
