import React from 'react';
import Cookies from 'js-cookie';
import Api from '../../../config/Api';
import ReportDefault from '../../../components/ReportDefault/ReportDefault';

class RelatorioPagamentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('TOKEN'),
      usuarioId: Cookies.get('USERNAME'),
    }
    this.situacaoOptions = [
      { value: 'RECEBIDO', label: 'Recebido do Cliente', info: 'Recebido do Cliente' },
      { value: 'IMPRESSO', label: 'Arquivo Impresso', info: 'Arquivo Impresso' },
      { value: 'LIBERADO', label: 'Arquivo Liberado', info: 'Arquivo Liberado' },
    ];

    this.api = new Api(this.state.token, this.state.usuarioId, 'relatorio-fila-impressao', (err) => {
      if (err.response.status === 401)
        this.props.history.push({
          pathname: '/login',
          state: { previousURL: this.props.location.pathname }
        });
      return Promise.reject(err);
    });
  }

  render() {
    return (
      <ReportDefault
        title='Fila de Impressão'
        usuarioId={this.state.usuarioId}
        api={this.api}
        getData={'getProdutos'}
        situacaoOptions={this.situacaoOptions}
        route='relatorio-fila-impressao'
      />
    );
  }
}

export default RelatorioPagamentos;
