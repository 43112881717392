import Moment from 'moment';

class DateHelper {
  stringfyMonth(monthId) {
    const months = ['Janeiro', 'Fevereiro',
      'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro',
      'Outubro', 'Novembro', 'Dezembro'];
    return months[monthId];
  }

  brMonths() {
    return ['Janeiro', 'Fevereiro',
      'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro',
      'Outubro', 'Novembro', 'Dezembro'];
  }

  static currentMonth() {
    const startOfMonth = Moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = Moment().endOf('month').format('YYYY-MM-DD 23:59:59');
    return {
      startOfMonth,
      endOfMonth
    }
  }

  static lastMonth() {
    const startOfMonth = Moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const endOfMonth = Moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD 23:59:59');
    return {
      startOfMonth,
      endOfMonth
    }
  }
}

export default DateHelper;