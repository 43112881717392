import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

import Api from '../config/Api';
import Header from "components/Navbars/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Notification from '../components/Notification/Notification';

import dashboardRoutes from '../routes/dashboard';

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "evoluire",
      activeColor: "black",
      token: Cookies.get('TOKEN'),
      usuario: Cookies.get('USUARIO'),
      routesToShow: [],
    };
    this.api = new Api();
    this.mainPanel = React.createRef();
  }
  async componentDidMount() {
    if (this.state.token && this.state.usuario) {
      try {
        const check = await this.api.validateToken(this.state.token);
        if (!check.data.id) {
          this.pushToLogin();
        }
        this.getRoutesToShow();
      } catch (error) {
        this.props.history.push({ pathname: '/login' });
        this.abrirNotificacaoErro('Erro de conexão.')
      }
    } else {
      this.pushToLogin();
    }

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  pushToLogin() {
    Cookies.remove('TOKEN');
    Cookies.remove('USUARIO');
    Cookies.remove('USERNAME');
    localStorage.clear();
    this.props.history.push({
      pathname: '/login',
      state: { previousURL: this.props.location.pathname }
    });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  getRoutesToShow = async () => {
    try {
      let userRoutes = [];
      if (this.props.location.state && this.props.location.state.routes) {
        userRoutes = this.props.location.state.routes;
      } else {
        const { data } = await (this.api.validateToken(this.state.token));
        userRoutes = data.routes;
      }
      const routesToShow = dashboardRoutes.filter((route) => {
        if (userRoutes.includes(route.nameToCheck)) return true;
        return false;
      });
      const pathname = this.props.location.pathname.replace('/', '');
      if (!userRoutes.includes(pathname)) {
        return this.pushToLogin();
      }
      return this.setState({
        routesToShow,
      });
    } catch (error) {
      this.pushToLogin();
    }

  }

  abrirNotificacaoErro = (message) => Notification
    .createNotification('error', message)
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={this.state.routesToShow}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <Header {...this.props} />
          <Switch>
            {this.state.routesToShow.map((prop, key) => {
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Dashboard;
