import React from "react";
import Moment from "moment";
import {
  FormGroup,
  Label,
  Input,
  Form,
  Col,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import Dropzone from "../../components/Dropzone/DropZone";
import CustomButton from "../../components/CustomButton/CustomButton";
import Base64Converter from "../../utils/Base64Converter";

class NovoProdutoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      produto: props.produto,
      disableSubmitButton: false,
      nome: props.produto.nome || "",
      arquivo: props.produto.arquivo || "",
      observacao: props.produto.observacao || "",
      quantidade: props.produto.quantidade || "",
      base: props.produto.base || "",
      altura: props.produto.altura || "",
      valorUnitario: props.produto.valorUnitario,
      valorTotal: props.produto.valorTotal,
      clientes: [],
      clienteId: props.produto.clienteId || "",
      dataCriacao: props.produto.createdAt
        ? Moment(props.produto.createdAt).format("YYYY-MM-DD")
        : "",
      previsaoEntrega: props.produto.previsaoEntrega
      ? Moment(props.produto.previsaoEntrega).format("YYYY-MM-DD")
      : "",
      loading: false,
    };
  } 

  async componentDidMount() {
    await this.getClientes(this.props.usuarioId);
  }

  getClientes = async (usuarioId) => {
    const { data } = await this.props.api.getAllClients(usuarioId);
    const clientes = data.map((cliente) => ({
      value: cliente.id,
      label: cliente.nome,
    }));
    this.setState({
      clientes,
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "quantidade") {
      this.handleValorTotal(value, this.state.valorUnitario);
    }
    if (name === "valorUnitario") {
      this.handleValorTotal(this.state.quantidade, value);
    }
    this.setState({
      [name]: value.toUpperCase(),
    });
  };

  handleCurrentUserAdminValues = (state) => {
    if (!this.props.currentUserAdmin && !this.props.edit) {
      return 0;
    }
    return state;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.disableSubmitButton();

    if (!this.props.edit && !this.state.arquivo) {
      this.props.abrirNotificacaoErro("Um arquivo precisa ser selecionado.");
      return this.disableSubmitButton();
    }

    if (this.props.currentUserAdmin && !this.state.clienteId) {
      this.props.abrirNotificacaoErro("Um cliente precisa ser selecionado.");
      return this.disableSubmitButton();
    }
    this.setState({ loading: true });

    try {
      const sendObject = {
        nome: this.state.nome,
        arquivo: this.state.arquivo,
        nomeArquivo:
          this.state.nomeArquivo && this.state.nomeArquivo.replace(/\s/g, ""),
        observacao: this.state.observacao,
        clienteId: this.handleCurrentUserAdminValues(this.state.clienteId),
        usuarioId: this.props.usuarioId,
        valorUnitario: this.handleCurrentUserAdminValues(
          this.state.valorUnitario
        ),
        valorTotal: this.handleCurrentUserAdminValues(this.state.valorTotal),
        base: this.state.base,
        altura: this.state.altura,
        quantidade: this.state.quantidade,
        situacao: this.props.situacao,
        currentUserAdmin: this.props.currentUserAdmin,
        previsaoEntrega: this.state.previsaoEntrega,
      };

      if (!this.props.edit) {
        await this.props.api.createProduct(sendObject);
      } else {
        await this.props.api.updateProduct(this.props.produto.id, sendObject);
      }
      this.props.modal();
      await this.props.loadData(0, { situacao: this.props.situacao });
      this.setState({ loading: false });
      this.props.abrirNotificacaoSucesso();
    } catch (err) {
      this.disableSubmitButton();
      console.log(err);
    }
  };

  disableSubmitButton = () => {
    this.setState((prevState) => ({
      disableSubmitButton: !prevState.disableSubmitButton,
    }));
  };

  handleSelectChange = (param) => (evt) => {
    this.setState({
      [param]: evt.value,
    });
  };

  handleValorTotal = (quantidade, valorUnitario) => {
    if (quantidade && valorUnitario) {
      const valorTotal = quantidade * valorUnitario;
      this.setState({
        valorTotal,
      });
    } else {
      this.setState({
        valorTotal: 0,
      });
    }
  };

  handleDecimalValue = (value, state) => {
    return state ? Number(state).toFixed(2) : Number(value).toFixed(2);
  };

  handleFilesUpload = async (files) => {
    const base64StringsFiles = await Promise.all(
      files.map((file) => Base64Converter.convert(file))
    );

    const fileName = files[0]?.path;
    const fileExtension = fileName.substr(fileName.indexOf("."));

    const fileType = files[0]?.type || fileExtension;

    const validTypes = [
      "image/png",
      "image/gif",
      "image/jpeg",
      "image/pdf",
      "application/vnd.corel-draw",
      "application/pdf",
      ".cdr",
    ];

    this.setState({
      arquivo: base64StringsFiles,
      nomeArquivo: fileName,
    });

    if (validTypes.includes(fileType)) {
      this.props.abrirNotificacaoSucesso(
        "O arquivo foi enviado e será processado."
      );
    } else {
      this.props.abrirNotificacaoErro("Formato de arquivo inválido!");
    }
  };

  render() {
    const { produto } = this.state;
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="nome">Arquivo</Label>
                <p>{`${
                  this.state.nomeArquivo || produto?.nameGoogleDrive || ""
                }`}</p>
                {!this.props.edit && (
                  <Dropzone
                    maxSize={10000000} // 10mb
                    acceptedFormats={[
                      "application/vnd.corel-draw",
                      "image/*",
                      "application/pdf",
                      "",
                    ]}
                    onSubmit={this.handleFilesUpload}
                    maxFiles={1}
                    validExtensions={["png", "gif", "jpeg", "pdf", "cdr"]}
                  >
                    <div>
                      <CustomButton type="button" id="file-avatar">
                        <i className="fa fa-file-image" /> Selecione o arquivo
                      </CustomButton>
                      <span style={{ marginLeft: "3rem" }}>Ou arraste...</span>
                    </div>
                  </Dropzone>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="quantidade">Quantidade</Label>
                <Input
                  type="number"
                  name="quantidade"
                  id="quantidade"
                  required
                  className="upper-case-input"
                  placeholder="Digite aqui"
                  defaultValue={produto.quantidade}
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="base">Base</Label>
                <Input
                  type="number"
                  name="base"
                  id="base"
                  required
                  placeholder="Digite aqui"
                  className="upper-case-input"
                  defaultValue={this.handleDecimalValue(produto.base)}
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                  step="0.001"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="altura">Altura</Label>
                <Input
                  type="number"
                  name="altura"
                  id="altura"
                  required
                  placeholder="Digite aqui"
                  className="upper-case-input"
                  defaultValue={this.handleDecimalValue(produto.altura)}
                  onChange={this.handleInputChange}
                  disabled={this.props.detail}
                  step="0.001"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="observacao">Observação</Label>
                <Input
                  type="textarea"
                  onChange={this.handleInputChange}
                  name="observacao"
                  id="observacao"
                  className="upper-case-input"
                  defaultValue={produto.observacao}
                />
              </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                  <Label for="previsaoEntrega">Previsão de Entrega</Label>
                  <Input
                    type="date"
                    name="previsaoEntrega"
                    id="previsaoEntrega"
                    placeholder="Digite aqui"
                    className="upper-case-input"
                    onChange={this.handleInputChange}
                    defaultValue={this.state.previsaoEntrega}
                    isDisabled={this.state.detail}
                  />
                </FormGroup>
              </Col>
          </Row>
          {this.props.edit && (
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="dataCriacao">Data Criação</Label>
                  <Input
                    type="date"
                    name="dataCriacao"
                    id="dataCriacao"
                    placeholder="Digite aqui"
                    className="upper-case-input"
                    defaultValue={this.state.dataCriacao}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          {this.props.currentUserAdmin && (
            <>
              <hr />
              <h5 className="separator-form">Cobrança</h5>
              <hr />
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="cliente">Cliente</Label>
                    <Select
                      options={this.state.clientes}
                      value={this.state.clientes.filter(
                        (fornecedor) =>
                          fornecedor.value === this.state.clienteId
                      )}
                      onChange={this.handleSelectChange("clienteId")}
                      placeholder="Selecione"
                      className="upper-case-input"
                      isDisabled={this.state.detail}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="valorUnitario">Valor Unitário</Label>
                    <Input
                      type="number"
                      name="valorUnitario"
                      id="valorUnitario"
                      required
                      placeholder="Digite aqui"
                      className="upper-case-input"
                      defaultValue={this.handleDecimalValue(
                        produto.valorUnitario,
                        this.state.valorUnitario
                      )}
                      onChange={this.handleInputChange}
                      disabled={this.props.detail}
                      step="0.001"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="valorTotal">Valor Total</Label>
                    <Input
                      type="number"
                      name="valorTotal"
                      id="valorTotal"
                      required
                      value={this.handleDecimalValue(
                        produto.valorTotal,
                        this.state.valorTotal
                      )}
                      onChange={this.handleInputChange}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          {!this.props.detail &&
          !this.state.loading &&
          !this.state.disableSubmitButton ? (
            <Button
              className="btn btn-primary"
              round="true"
              type="submit"
              disabled={this.state.disableSubmitButton}
            >
              Salvar <i className="fas fa-save"></i>
            </Button>
          ) : (
            <div className="group-input-custom-content">
              <Spinner size="sm" color="primary" />
            </div>
          )}
        </Form>
      </>
    );
  }
}

export default NovoProdutoForm;
