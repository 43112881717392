import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Spinner,
} from "reactstrap";
import FilaImpressaoPDF from "../../views/Relatorios/FilaImpressao/ProdutosPDF";
import FilaItensPDF from "../../views/Relatorios/FilaProdutos/ItensPDF";
import Select from "react-select";
import Notification from "../Notification/Notification";
import Moment from "moment";
import CustomCollapse from "../Collapse/CustomCollapse";
import PeriodSelect from "../Select/PeriodSelect";

const ReportDefault = (props) => {
  const [loading, setLoading] = useState(false);
  const [printReportModal, setPrintReporModal] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState("");
  const [reportData, setReportData] = useState([]);
  const [situacao, setSituacao] = useState("");
  const [clienteId, setClienteId] = useState("");
  const [dataInicio, setDataInicio] = useState(
    Moment().startOf("month").format("YYYY-MM-DD")
  );
  const [dataFim, setDataFim] = useState(
    Moment().endOf("month").format("YYYY-MM-DD 23:59:59")
  );
  const [dataInicioRelatorio, setDataInicioRelatorio] = useState(
    Moment().startOf("month").format("YYYY-MM-DD")
  );
  const [dataFimRelatorio, setDataFimRelatorio] = useState(
    Moment().endOf("month").format("YYYY-MM-DD 23:59:59")
  );

  useEffect(() => {
    const getClientes = async () => {
      const { data } = await props.api.getAllClients(
        props.usuarioId,
        props.route
      );
      const clientes = data.map((cliente) => ({
        value: cliente.id,
        label: cliente.nome,
        info: cliente,
      }));
      setClientes(clientes);
    };
    getClientes();
  }, [props.api, props.usuarioId, props.route]);

  const handleSetPeriod = (name, date) => {
    if (!name) {
      return;
    }
    if (name === "dataInicio") {
      setDataInicio(date);
    }
    if (name === "dataFim") {
      setDataFim(date);
    }
    if (name === "dataInicioRelatorio") {
      setDataInicioRelatorio(date);
    }
    setDataFimRelatorio(date);
  };

  const handlePeriod = (evt) => {
    const startOfMonth = evt.startOfMonth;
    const endOfMonth = evt.endOfMonth;
    const name1 = evt.destinoUm;
    const name2 = evt.destinoDois;
    handleSetPeriod(name1, startOfMonth);
    handleSetPeriod(name2, endOfMonth);
    setReportData([]);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    handleSetPeriod(name, value);
    setReportData([]);
  };

  const handleClienteChange = (evt) => {
    const value = evt && evt.value;
    setClienteId(value);
    setCliente(evt);
    setReportData([]);
  };

  const handleSelectChange = (property) => (evt) => {
    if (property === "situacao") {
      setSituacao(evt);
    }
    setReportData([]);
  };

  const toogleReport = () => {
    setPrintReporModal(!printReportModal);
  };

  const abriNotificacaoInfo = () =>
    Notification.createNotification(
      "info",
      "Nenhuma informação retornada para esta busca!",
      "Aviso"
    );

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);

      const sendObject = {
        userId: props.usuarioId,
        dataInicio: dataInicio,
        dataFim: dataFim,
        dataInicioRelatorio: dataInicioRelatorio,
        dataFimRelatorio: dataFimRelatorio.includes("23:59:59")
          ? dataFimRelatorio
          : `${dataFimRelatorio} 23:59:59`,
        situacao: situacao && situacao.value,
        clienteId: clienteId,
        report: true,
      };

      const { data } = await props.api[props.getData](null, null, sendObject);
      setLoading(false);
      if (!data.rows.length) {
        abriNotificacaoInfo();
      } else {
        setReportData(data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{props.title}</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <Form onSubmit={handleSubmit}>
                      <PeriodSelect
                        titulo="Filtrar Data"
                        handlePeriod={handlePeriod}
                        handleInputChange={handleInputChange}
                        dataUm={
                          props.route === "relatorio-fila-impressao"
                            ? "dataInicioRelatorio"
                            : "dataInicio"
                        }
                        dataDois={
                          props.route === "relatorio-fila-impressao"
                            ? "dataFimRelatorio"
                            : "dataFim"
                        }
                        renderOpen={true}
                      />
                      <CustomCollapse titulo="Filtrar Cliente">
                        <Row>
                          <Col md="12">
                            <FormGroup className="space-card-filter">
                              <Select
                                options={clientes}
                                value={clientes.filter(
                                  (fornecedor) => fornecedor.value === clienteId
                                )}
                                onChange={handleClienteChange}
                                placeholder="Selecione"
                                isClearable
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CustomCollapse>
                      <CustomCollapse titulo="Filtrar Situação">
                        <Row>
                          <Col md="12">
                            <FormGroup className="space-card-filter">
                              <Select
                                options={props.situacaoOptions}
                                defaultValue={situacao}
                                onChange={handleSelectChange("situacao")}
                                placeholder="Selecione"
                                isClearable
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CustomCollapse>
                      <Button type="submit" className="custom-spinner-btn">
                        {!loading ? (
                          "Gerar Relatório"
                        ) : (
                          <div className="load-spinner-center-table-pagined">
                            <Spinner size="sm" color="white" />
                          </div>
                        )}
                      </Button>
                      {reportData.length > 0 && !loading && (
                        <>
                          <Button
                            color="primary"
                            type="button"
                            onClick={toogleReport}
                          >
                            Gerar PDF <i className="fas fa-file-pdf"></i>
                          </Button>
                        </>
                      )}
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {reportData.length > 0 && !loading && (
          <Modal
            className="new-expense-modal modal-90w"
            isOpen={printReportModal}
            toggle={toogleReport}
          >
            <ModalHeader toggle={toogleReport}> Imprimir relatorio</ModalHeader>
            <ModalBody>
              {props.getData === "getProdutos" && (
                <FilaImpressaoPDF
                  data={reportData}
                  situacao={situacao && situacao.label}
                  cliente={cliente}
                  dataInicioRelatorio={dataInicioRelatorio}
                  dataFimRelatorio={dataFimRelatorio}
                />
              )}
              {props.getData === "getItens" && (
                <FilaItensPDF
                  data={reportData}
                  situacao={situacao && situacao.label}
                  cliente={cliente}
                  dataInicio={dataInicio}
                  dataFim={dataFim}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toogleReport}>
                Fechar
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ReportDefault;
