import axios from 'axios';

export default class Api {
  constructor(token, usuarioId, path, onError) {
    this.axios = axios.create({
      baseURL: 'https://api-fila-impressao.gradusrs.com.br/',
      // baseURL: 'http://localhost:3006/',
      headers: {
        Authorization: token,
        usuarioId,
        path,
      },
    });

    this.axios.interceptors.response.use((response) => response, onError);
  }

  // Login

  login(username, password, admin) {
    return this.axios.post('/login', { username, password });
  }

  googleLogin(accessToken, profileObj) {
    return this.axios.post('/login-google', { accessToken, profileObj });
  }

  validateToken(token) {
    return this.axios.post('/usuario/check-token', { token });
  }

  // Clientes

  getClientes = async (limit = 10, offset = 0, filters) => {
    try {
      const response = await this.axios.get(`/cliente/${filters.userId}?offset=${offset}&limit=${limit}`, {
        params: {
          ...filters,
        }
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  }

  getAllClients = (usuarioId, relatorio) => {
    return this.axios
      .get(`/cliente/get-all/${usuarioId}?relatorio=${relatorio || 'todosClientes'}`);
  }

  getSingleClient(clienteId) {
    return this.axios.get(`/cliente/show/${clienteId}`);
  }

  getClinteByDocument(documento, usuarioId, allowGetClient) {
    return this.axios.post('/cliente/get-by-document', { documento, usuarioId, allowGetClient });
  }

  createClient(client, allowGetClient) {
    return this.axios.post('/cliente/create', { client, allowGetClient });
  }

  updateClient(clienteId, client, allowGetClient) {
    return this.axios.put(`/cliente/update/${clienteId}`, { client, allowGetClient });
  }

  deleteClient(clienteId) {
    return this.axios.delete(`/cliente/delete/${clienteId}`);
  }

  getAddressByCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json`);
  }

  createUserByClient(user) {
    return this.axios.post('/cliente/create-user', user);
  }

  // Produtos

  getProdutos = async (limit = 10, offset = 0, filters) => {
    try {
      const response = await this.axios.get(`/produto/${filters.userId}?offset=${offset}&limit=${limit}`, {
        params: {
          ...filters,
        }
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  }

  getSingleProduct(produtoId) {
    return this.axios.get(`/produto/show/${produtoId}`);
  }

  createProduct(produto) {
    return this.axios.post('/produto/create', produto);
  }

  updateProduct(productId, product) {
    return this.axios.put(`/produto/update/${productId}`, product);
  }

  deleteProduct(productId) {
    return this.axios.delete(`/produto/delete/${productId}`);
  }

  liberarProduto(productId, product) {
    return this.axios.put(`/produto/liberar/${productId}`, product);
  }

  uploadFiles(files) {
    return this.axios.post('/produto/upload-files', files);
  }

  // Itens

  getItens = async (limit = 10, offset = 0, filters) => {
    try {
      const response = await this.axios.get(`/item/${filters.userId}?offset=${offset}&limit=${limit}`, {
        params: {
          ...filters,
        }
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  }

  getSingleItem(itemId) {
    return this.axios.get(`/item/show/${itemId}`);
  }

  createItem(item) {
    return this.axios.post('/item/create', item);
  }

  updateItem(itemId, item) {
    return this.axios.put(`/item/update/${itemId}`, item);
  }

  liberarItem(itemId, item) {
    return this.axios.put(`/item/liberar/${itemId}`, item);
  }

  deleteItem(itemId) {
    return this.axios.delete(`/item/delete/${itemId}`);
  }
}