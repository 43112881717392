import React from "react";
// import Moment from 'moment';
import {
  FormGroup,
  Label,
  // Input,
  Form,
  Col,
  Row,
  Button,
  Collapse,
} from "reactstrap";
import Select from 'react-select';
import ItemProduto from './ItemProduto';
import ItemTela from './ItemTela';

class NovoItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      clientes: [],
      clienteId: props.item.clienteId || '',
      itemProduto: props.item.itemProduto || [{
        nome: '',
        unidade: 0,
        quantidade: 0,
        valorUnitario: 0,
        valorTotal: 0,
      }],
      itemTela: props.item.itemTela || [{
        base: 0,
        altura: 0,
        perfil: '',
        lavar: null,
        esticar: null,
        gravar: null,
        tecido: '',
        emulsao: '',
        observacao: '',
        valorTotal: 0,
        quantidade: 0,
      }],
      collapseItemProd: false,
      collapseItemTela: false,
    }
  }

  async componentDidMount() {
    await this.getClientes(this.props.usuarioId);
  }

  getClientes = async (usuarioId) => {
    const { data } = await this.props.api.getAllClients(usuarioId);
    const clientes = data.map((cliente) => ({ value: cliente.id, label: cliente.nome }));
    this.setState({
      clientes,
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  checkItemDynamicInputs = (lista) => {
    let variableCheckNull;
    lista.forEach((itemBase) => {
      const item = itemBase;
      if (this.props.currentUserAdmin) {
        item.valorTotal = Number(itemBase.valorTotal);
        if (item.valorTotal === 0) {
          variableCheckNull = true;
        } else {
          variableCheckNull = false;
        }
      } else {
        item.quantidade = Number(itemBase.quantidade);
        if (item.quantidade === 0) {
          variableCheckNull = true;
        } else {
          variableCheckNull = false;
        }
      }
    });
    return variableCheckNull;
  }

  handleCurrentUserAdminValues = (state, lista) => {
    if (this.props.currentUserAdmin && !lista) {
      return state.length === 1 && state[0].valorTotal === 0 ? false : true;
    }
    if (this.props.currentUserAdmin && lista) {
      return state.length === 1 && state[0].valorTotal === 0 ? null : state;
    }
    if (!this.props.currentUserAdmin && !lista) {
      return state.length === 1 && state[0].quantidade === 0 ? false : true;
    }
    // if (!this.props.currentUserAdmin && lista) {
    return state.length === 1 && state[0].quantidade === 0 ? null : state;
    // }
  }

  handleCurrentUserAdminClienteId = (state) => {
    if (!this.props.currentUserAdmin && !this.props.edit) {
      return 0;
    }
    return state;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.disableSubmitButton();

    const { clienteId, itemTela, itemProduto } = this.state;

    if (this.props.currentUserAdmin && !this.state.clienteId) {
      this.props.abrirNotificacaoErro('Um cliente precisa ser selecionado.');
      return this.disableSubmitButton();
    }

    if (this.checkItemDynamicInputs(itemTela) && this.checkItemDynamicInputs(itemProduto)) {
      this.props.abrirNotificacaoErro('Um produto ou uma tela devem ser cadastrados.');
      return this.disableSubmitButton();
    }
    try {
      const sendObject = {
        clienteId: this.handleCurrentUserAdminClienteId(clienteId),
        usuarioId: this.props.usuarioId,
        situacao: this.props.situacao,
        produto: this.handleCurrentUserAdminValues(itemProduto, false),
        telaSerigrafia: this.handleCurrentUserAdminValues(itemTela, false),
        itemTela: this.handleCurrentUserAdminValues(itemTela, true),
        itemProduto: this.handleCurrentUserAdminValues(itemProduto, true),
        currentUserAdmin: this.props.currentUserAdmin,
      }

      if (!this.props.edit) {
        await this.props.api.createItem(sendObject);
      } else {
        await this.props.api.updateItem(this.props.item.id, sendObject);
      }
      this.props.modal();
      this.props.loadData(0, { situacao: this.props.situacao });
      this.props.abrirNotificacaoSucesso();
    } catch (err) {
      this.disableSubmitButton();
      console.log(err);
    }
  }

  disableSubmitButton = () => {
    this.setState((prevState) => ({ disableSubmitButton: !prevState.disableSubmitButton }));
  }

  handleSelectChange = param => evt => {
    this.setState({
      [param]: evt.value,
    });
  }

  // Item tela lógica

  handleAddItemTela = () => {
    this.setState({
      itemTela: this.state.itemTela.concat([
        {
          base: 0,
          altura: 0,
          perfil: '',
          lavar: null,
          esticar: null,
          gravar: null,
          tecido: '',
          emulsao: '',
          observacao: '',
          valorTotal: 0,
          quantidade: 0,
        }
      ]),
    });
  };

  handleItemTelaPropChange = (idx, prop) => (evt) => {
    const newItemTela = this.state.itemTela.map((itemTela, sidx) => {
      if (idx !== sidx) {
        return itemTela;
      }
      const event = evt.target ? evt.target.type === 'checkbox'
        ? evt.target.checked : evt.target.value : '';
      return { ...itemTela, [prop]: event };
    });
    this.setState({ itemTela: newItemTela });
  };

  handleRemoveItemTela = (idx) => () => {
    this.setState({
      itemTela: this.state.itemTela.filter((s, sidx) => idx !== sidx)
    });
  };

  // Item produto lógica

  handleItemProdutoPropValueChange = (idx, prop) => (evt) => {
    const newItemProduto = this.state.itemProduto.map((itemProd, sidx) => {
      if (idx !== sidx) {
        return itemProd;
      }
      let value = evt.target && evt.target.value;
      let valorTotal = 0;
      if (prop === 'quantidade') {
        valorTotal = value * itemProd.valorUnitario;
      }
      if (prop === 'valorUnitario') {
        valorTotal = value * itemProd.quantidade;
      }
      itemProd.valorTotal = valorTotal;
      
      return { ...itemProd, [prop]: value };
    });
    this.setState({ itemProduto: newItemProduto });
  };

  handleItemProdutoPropChange = (idx, prop) => (evt) => {
    const newItemProduto = this.state.itemProduto.map((itemProd, sidx) => {
      if (idx !== sidx) {
        return itemProd;
      }
      let value = evt.target && evt.target.value;

      if (prop === 'unidade') {
        value = evt.value;
      }
      
      return { ...itemProd, [prop]: value };
    });
    this.setState({ itemProduto: newItemProduto });
  };

  handleAddItemProduto = () => {
    this.setState({
      itemProduto: this.state.itemProduto.concat([
        {
          nome: '',
          unidade: 0,
          quantidade: 0,
          valorUnitario: 0,
          valorTotal: 0,
          observacao: '',
        }
      ]),
    });
  };

  handleRemoveItemProduto = (idx) => () => {
    this.setState({
      itemProduto: this.state.itemProduto.filter((s, sidx) => idx !== sidx)
    });
  };

  toggleCollapseItemTela = () => {
    this.setState(state => ({ collapseItemTela: !state.collapseItemTela }));
  }

  toggleCollapseItemProd = () => {
    this.setState(state => ({ collapseItemProd: !state.collapseItemProd }));
  }

  render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          {this.props.currentUserAdmin && (
            <>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <Label for="cliente">Cliente</Label>
                    <Select
                      options={this.state.clientes}
                      value={this.state.clientes
                        .filter((fornecedor) => fornecedor.value === this.state.clienteId)}
                      onChange={this.handleSelectChange('clienteId')}
                      placeholder="Selecione"
                      className="upper-case-input"
                      isDisabled={this.state.detail}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
            </>
          )}
          <h5>Produto
          {!this.state.collapseItemProd && (
              <Button color="primary" fab="true"
                onClick={this.toggleCollapseItemProd}
                className="btn-round btn-icon round-btn-add">
                <i className="fa fa-plus"></i>
              </Button>
            )}
            {this.state.collapseItemProd && (
              <Button color="primary" fab
                onClick={this.toggleCollapseItemProd}
                className="btn-round btn-icon round-btn-add">
                <i className="fa fa-minus"></i>
              </Button>
            )}
          </h5>
          <Collapse isOpen={this.state.collapseItemProd}>
            <ItemProduto
              produtos={this.state.itemProduto}
              handleItemProdutoPropChange={this.handleItemProdutoPropChange}
              handleAddItemProduto={this.handleAddItemProduto}
              handleRemoveItemProduto={this.handleRemoveItemProduto}
              currentUserAdmin={this.props.currentUserAdmin}
              handleItemProdutoPropValueChange={this.handleItemProdutoPropValueChange}
            />
          </Collapse>
          <hr />
          <h5>Tela de Serigrafia
          {!this.state.collapseItemTela && (
              <Button color="primary"
                onClick={this.toggleCollapseItemTela}
                className="btn-round btn-icon round-btn-add">
                <i className="fa fa-plus"></i>
              </Button>
            )}
            {this.state.collapseItemTela && (
              <Button color="primary"
                onClick={this.toggleCollapseItemTela}
                className="btn-round btn-icon round-btn-add">
                <i className="fa fa-minus"></i>
              </Button>
            )}
          </h5>
          <Collapse isOpen={this.state.collapseItemTela}>
            <ItemTela
              telas={this.state.itemTela}
              handleAddItemTela={this.handleAddItemTela}
              handleItemTelaPropChange={this.handleItemTelaPropChange}
              handleRemoveItemTela={this.handleRemoveItemTela}
              currentUserAdmin={this.props.currentUserAdmin}
            />
          </Collapse>
          <br />
          <Button
            color="success"
            type="submit"
            disabled={this.state.disableSubmitButton}>
            Salvar  <i className="fas fa-save"></i>
          </Button>
        </Form>
      </>
    )
  }
}

export default NovoItemForm;