import React from 'react';
import {
  Card,
  Col,
  Collapse,
} from 'reactstrap';

class CustomCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: this.props.renderOpen ? this.props.renderOpen : false,
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  }

  render() {
    return (
      <Card className="card-filtro-Relatorios">
        <Col md={12}>
          <div onClick={this.toggleCollapse}>
            <h1 className="title-collapse-Relatorios">{this.props.titulo}</h1>
            {!this.state.collapse && (
              <div onClick={this.toggleCollapse} className="icon-card-filtro-Relatorios">
                <i onClick={this.toggleCollapse} className="fa fa-plus"></i>
              </div>)}
            {this.state.collapse && (
              <div onClick={this.toggleCollapse} className="icon-card-filtro-Relatorios">
                <i onClick={this.toggleCollapse} className="fa fa-minus"></i>
              </div>)}
          </div>
          <Collapse isOpen={this.state.collapse}>
            <div>
              {this.props.children}
            </div>
          </Collapse>
        </Col>
      </Card>
    )
  }
}

export default CustomCollapse;